import { isEmpty } from "lodash";
import React from "react";
import { FaRegTrashAlt, FaRegPlusSquare } from "react-icons/fa";
import Dropzone from "react-dropzone";
import ImageDisplayField from "./ImageDisplayField";

const Questions = (props) => {
  const {
    handleSubmit,
    provided,
    snapshot,
    Draggable,
    onSubmit,
    register,
    onChange,
    data,
    onRemoveField,
    handleAddQuestion,
    handleAddSection,
    queTypeList,
    handleAddRadioORCheckbox,
    handleRemoveRadio,
    onCancel,
    onRemoveSection,
    errors,
    onDrop,
    deleteFile,
  } = props;
  let sectionCount = 0;

  const getQueIndex = (question, i) => {
    const arr = Object.keys(question).filter((que) =>
      que.includes("field_text_section")
    );
    const index = arr[0].slice(-1);
    return index || i;
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.droppableProps}
      {...provided.droppableProps}
      style={{
        backgroundColor: snapshot.isDraggingOver ? "#eee" : "transparent",
      }}
    >
      <div className="flex justify-end mb-5" style={{ width: "75%" }}>
        <button
          type="button"
          className="flex shadow bg-success-600 mr-2 hover:bg-success-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
          onClick={handleAddSection}
        >
          Add Section
          <span className="ml-2 mt-[3px] text-lg">
            {" "}
            <FaRegPlusSquare />{" "}
          </span>
        </button>
      </div>
      <Draggable key={`sections`} draggableId={"sections_1"} index={1}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            // {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ minHeight: "50px" }}
          >
            {data &&
              Object.keys(data).map((section, ind) => {
                if (!isEmpty(data[section])) {
                  sectionCount++;
                  const sectionIndex = section.slice(-1);
                  return (
                    <div
                      key={`${section}_${ind}`}
                      className="flex justify-center m-auto w-3/6"
                    >
                      <div id="accordion" className="m-[10px] w-full">
                        <div className="rounded-t-lg border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800 ">
                          <h2 className="mb-0" id="headingOne">
                            <div
                              className="group relative flex w-full items-center rounded-t-[15px] border-0 bg-white py-4 px-5 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                              type="button"
                              data-te-collapse-init
                              data-te-target={`#section_${sectionIndex}`}
                              aria-expanded="true"
                              aria-controls={`section_${sectionIndex}`}
                            >
                              Section {sectionCount}
                              <div className="text-right ml-auto">
                                <button
                                  type="button"
                                  className="mt-1 inline-block rounded border-2 border-danger ml-14 px-2 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                  onClick={(e) => onRemoveSection(e, section)}
                                >
                                  <FaRegTrashAlt />
                                </button>
                              </div>
                              <span className="ml-5 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="currentColor"
                                  className="h-6 w-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </span>
                            </div>
                          </h2>
                          <div
                            id={`section_${sectionIndex}`}
                            className="!visible"
                            data-te-collapse-item
                            data-te-collapse-show
                            data-te-parent="#accordion"
                          >
                            <div className="">
                              <div className="flex justify-center">
                                <div className="block w-full rounded-lg bg-white text-center shadow-lg dark:bg-neutral-700">
                                  <div className="text-left border-b-2 border-neutral-100 py-3 px-6 dark:border-neutral-600 dark:text-neutral-50">
                                    <input
                                      id={`section_title_${sectionIndex}`}
                                      name={`section_title_${sectionIndex}`}
                                      {...register(
                                        `section_title_${sectionIndex}`
                                      )}
                                      type="text"
                                      className="shadow appearance-none border border-gray-200 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                      placeholder="Enter Section Title"
                                    />
                                    <p className="py-2 text-danger">
                                      {errors[
                                        `section_title_${sectionIndex}`
                                      ] &&
                                        errors[`section_title_${sectionIndex}`]
                                          .message}
                                    </p>
                                  </div>
                                  <div className="p-2">
                                    <div className="flex flex-wrap justify-center w-full">
                                      {data[section]?.questions?.map(
                                        (question, i) => {
                                          const questionIndex = getQueIndex(
                                            question,
                                            i
                                          );
                                          const queFieldType =
                                            question[
                                              `field_type_section_${sectionIndex}_${questionIndex}`
                                            ];
                                          return (
                                            <div
                                              className="rounded border-solid border-2 m-4 p-3 overflow-hidden shadow-lg w-full"
                                              key={i}
                                            >
                                              <div className="grid grid-flow-col gap-3">
                                                <div className="">
                                                  <input
                                                    id={`field_text_section_${sectionIndex}_${questionIndex}`}
                                                    name="field_text"
                                                    {...register(
                                                      `field_text_section_${sectionIndex}_${questionIndex}`
                                                    )}
                                                    type="text"
                                                    className="shadow appearance-none border border-gray-200 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    placeholder="Field"
                                                    onChange={(e) =>
                                                      onChange(
                                                        e,
                                                        questionIndex,
                                                        section,
                                                        sectionIndex
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="">
                                                  <select
                                                    id={`field_type_section_${sectionIndex}_${questionIndex}`}
                                                    name="question_type"
                                                    {...register(
                                                      `field_type_section_${sectionIndex}_${questionIndex}`
                                                    )}
                                                    className="shadow appearance-none border border-gray-200 rounded ml-10 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    placeholder="Please Select"
                                                    onChange={(e) =>
                                                      onChange(
                                                        e,
                                                        questionIndex,
                                                        section,
                                                        sectionIndex
                                                      )
                                                    }
                                                  >
                                                    <option value="">
                                                      Please Select
                                                    </option>
                                                    {queTypeList.map((item) => (
                                                      <option
                                                        key={item.label}
                                                        value={item.value}
                                                      >
                                                        {item.label}
                                                      </option>
                                                    ))}
                                                  </select>
                                                </div>
                                                <div className="">
                                                  <button
                                                    type="button"
                                                    className="mt-1 inline-block rounded border-2 border-danger ml-14 px-2 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                                    onClick={(e) =>
                                                      onRemoveField(
                                                        e,
                                                        questionIndex,
                                                        section
                                                      )
                                                    }
                                                  >
                                                    <FaRegTrashAlt />
                                                  </button>
                                                </div>
                                              </div>
                                              {queFieldType && (
                                                <div className="flex flex-col text-left rounded border-solid border-2 m-auto mt-5 w-full p-3 ">
                                                  {queFieldType === "text" ||
                                                  queFieldType === "number" ||
                                                  queFieldType === "date" ? (
                                                    <input
                                                      id={`type_info_section_${sectionIndex}_${questionIndex}`}
                                                      type={queFieldType}
                                                      disabled={true}
                                                      {...register(
                                                        `type_info_section_${sectionIndex}_${questionIndex}`,
                                                        {}
                                                      )}
                                                      placeholder={`${
                                                        queFieldType === "text"
                                                          ? "Textbox"
                                                          : "Number Input"
                                                      }`}
                                                      name={`type_info_section_${sectionIndex}_${questionIndex}`}
                                                      className="shadow appearance-none border border-gray-200 rounded ml-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    ></input>
                                                  ) : queFieldType ===
                                                    "info" ? (
                                                    <input
                                                      id={`field_info_section_${sectionIndex}_${questionIndex}`}
                                                      type={queFieldType}
                                                      disabled={false}
                                                      {...register(
                                                        `field_info_section_${sectionIndex}_${questionIndex}`,
                                                        {}
                                                      )}
                                                      placeholder={`Enter info text here...`}
                                                      name={`field_info_section_${sectionIndex}_${questionIndex}`}
                                                      className="shadow appearance-none border border-gray-200 rounded ml-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    ></input>
                                                  ) : queFieldType ===
                                                    "textarea" ? (
                                                    <textarea
                                                      id={`paragraph_${questionIndex}`}
                                                      {...register(
                                                        `paragraph_section_${sectionIndex}_${questionIndex}`,
                                                        {}
                                                      )}
                                                      placeholder="Long answer text"
                                                      disabled={true}
                                                      name={`paragraph_${sectionIndex}_${questionIndex}`}
                                                      className="shadow appearance-none border border-gray-200 rounded ml-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    ></textarea>
                                                  ) : queFieldType ===
                                                      "radio" ||
                                                    queFieldType ===
                                                      "checkbox" ? (
                                                    <>
                                                      {question.children &&
                                                        question.children
                                                          .length > 0 &&
                                                        question.children.map(
                                                          (
                                                            child,
                                                            child_index
                                                          ) => {
                                                            return (
                                                              <div className="mt-4">
                                                                <input
                                                                  id={`${queFieldType}_section_${sectionIndex}_${questionIndex}-${child_index}`}
                                                                  {...register(
                                                                    `${queFieldType}_section_${sectionIndex}_${questionIndex}-${child_index}`,
                                                                    {}
                                                                  )}
                                                                  disabled={
                                                                    true
                                                                  }
                                                                  type={
                                                                    queFieldType
                                                                  }
                                                                  placeholder="Option 1"
                                                                  name={`${queFieldType}_section_${sectionIndex}_${questionIndex}${child_index}`}
                                                                  className="w-4 h-4 border-gray-500  ml-3 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                                                                ></input>
                                                                <input
                                                                  id={`${queFieldType}_text_section_${sectionIndex}_${questionIndex}-${child_index}`}
                                                                  type="text"
                                                                  {...register(
                                                                    `${queFieldType}_text_section_${sectionIndex}_${questionIndex}-${child_index}`,
                                                                    {}
                                                                  )}
                                                                  placeholder={
                                                                    queFieldType ===
                                                                    "radio"
                                                                      ? "Radio Box Label"
                                                                      : "Checkbox Label"
                                                                  }
                                                                  name={`${queFieldType}_text_section_${sectionIndex}_${questionIndex}-${child_index}`}
                                                                  className="shadow appearance-none border border-gray-200 rounded ml-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                                ></input>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      <div className="flex justify-end mt-2">
                                                        <button
                                                          type="button"
                                                          className="inline-block rounded border-2 border-primary mr-2 px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                                          onClick={() =>
                                                            handleAddRadioORCheckbox(
                                                              i,
                                                              queFieldType,
                                                              section,
                                                              sectionIndex
                                                            )
                                                          }
                                                        >
                                                          Add {queFieldType}
                                                        </button>
                                                        {question["children"]
                                                          ?.length > 1 && (
                                                          <button
                                                            type="button"
                                                            className="inline-block rounded border-2 border-danger px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                                            onClick={() =>
                                                              handleRemoveRadio(
                                                                i,
                                                                section
                                                              )
                                                            }
                                                          >
                                                            Remove{" "}
                                                            {queFieldType}
                                                          </button>
                                                        )}
                                                      </div>
                                                    </>
                                                  ) : queFieldType ===
                                                    "file" ? (
                                                    <div>
                                                      <div className="border border-dashed p-3 border-gray-500 relative">
                                                        <Dropzone
                                                          onDrop={(files) =>
                                                            onDrop(
                                                              files,
                                                              section,
                                                              sectionIndex,
                                                              questionIndex
                                                            )
                                                          }
                                                        >
                                                          {({
                                                            getRootProps,
                                                            getInputProps,
                                                            isDragActive,
                                                          }) => (
                                                            <div
                                                              {...getRootProps(
                                                                {}
                                                              )}
                                                            >
                                                              <input
                                                                {...getInputProps()}
                                                              />
                                                              {isDragActive ? (
                                                                <p>
                                                                  Drop the files
                                                                  here ...
                                                                </p>
                                                              ) : (
                                                                <p>
                                                                  Drag 'n' drop
                                                                  some files
                                                                  here, or click
                                                                  to select
                                                                  files
                                                                </p>
                                                              )}
                                                            </div>
                                                          )}
                                                        </Dropzone>
                                                      </div>
                                                      <ImageDisplayField
                                                        question={question}
                                                        questionIndex={
                                                          questionIndex
                                                        }
                                                        section={section}
                                                        deleteFile={deleteFile}
                                                        sectionIndex={
                                                          sectionIndex
                                                        }
                                                      />
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              )}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex justify-end border-t-2 border-neutral-100 py-3 px-6 dark:border-neutral-600 dark:text-neutral-50">
                                    <button
                                      type="button"
                                      className="flex shadow bg-primary-600 mr-2 hover:bg-primary-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                      onClick={() =>
                                        handleAddQuestion(sectionIndex)
                                      }
                                    >
                                      Add Form Element{" "}
                                      <span className="ml-2 mt-[3px] text-lg">
                                        {" "}
                                        <FaRegPlusSquare />{" "}
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
          </div>
        )}
      </Draggable>
      <div className="flex justify-end" style={{ width: "75%" }}>
        <button
          type="button"
          className="shadow bg-success-600 mr-2 hover:bg-success-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </button>

        <button
          type="button"
          className="shadow bg-danger-600 mr-2 hover:bg-danger-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Questions;
