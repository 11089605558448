const intialState = {
  isLoggedIn: false,
};

const loginReducer = (state = intialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, isLoggedIn: true };
    case "LOGOUT":
      return { ...state, isLoggedIn: false };
    default:
      return { ...state };
  }
};

export default loginReducer;
