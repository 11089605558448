import React from "react";
import AddQuestionnaire from "./Form";

const FormBuilder = () => {
  return (
    <>
      <AddQuestionnaire />
    </>
  );
};

export default FormBuilder;
