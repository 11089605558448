import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import "../css/table.css";
import { visuallyHidden } from "@mui/utils";
import { debounce } from "lodash";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function TableHeaderSection({
  handleChangeSearch,
  // cancelSearch,
  // searchVal,
  headingText,
  componentDisplay,
  searchBar,
}) {
  return (
    <Toolbar className="flex md:flex-row sm:flex-col xs:flex-col xs:my-5">
      <Typography
        sx={{ flex: "1 1 100%", fontSize: "23px" }}
        variant="h6"
        id="tableTitle"
        component="div"
        className="text-success"
      >
        {headingText}
      </Typography>

      {componentDisplay ? componentDisplay() : <div className=""></div>}
      {searchBar && (
        <div className="relative text-gray-600 focus-within:text-gray-400">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <button
              type="submit"
              className="p-1 focus:outline-none focus:shadow-outline"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </span>
          <input
            type="search"
            name="search"
            onChange={(e) => handleChangeSearch(e)}
            className="py-2 text-sm text-gray-900 bg-white-900 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900"
            placeholder="Search..."
          />
        </div>
      )}
    </Toolbar>
  );
}

TableHeaderSection.propTypes = {
  handleChangeSearch: PropTypes.func.isRequired,
  cancelSearch: PropTypes.func.isRequired,
  searchVal: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
};

function EnhancedTableHead(props) {
  const { order, orderBy, headCells, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className="table-header">
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              className="font-bold text-base capitalize"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export const TableComponent = (props) => {
  const {
    numSelected,
    order,
    orderBy,
    tableRowsData,
    headCells,
    searchVal,
    headingText,
    setOrder,
    setOrderBy,
    setSearchVal,
    rowsPerPage,
    getAllData,
    componentDisplay = null,
    searchBar = true,
  } = props;

  const debounceSearch = debounce((searchValue) => {
    setSearchVal(searchValue);
    getAllData(1, rowsPerPage, searchValue);
  }, 750);

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    debounceSearch(value);
  };

  const cancelSearch = () => {
    setSearchVal("");
    getAllData(1, rowsPerPage, "");
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <React.Fragment>
      <TableHeaderSection
        numSelected={numSelected}
        handleChangeSearch={handleChangeSearch}
        cancelSearch={cancelSearch}
        searchVal={searchVal}
        headingText={headingText}
        componentDisplay={componentDisplay}
        searchBar={searchBar}
      />
      <TableContainer style={{ maxHeight: 580 }}>
        <Table
          style={{ minWidth: 750 }}
          stickyHeader
          aria-labelledby={headingText}
        >
          <EnhancedTableHead
            numSelected={numSelected}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={tableRowsData.length || 0}
            headCells={headCells}
          />
          {props.children}
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
