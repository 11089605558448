import React from "react";
import * as te from "tw-elements";
import FormBuilderCardDisplay from "./Components/FormBuilder/FormBuilderCardDisplay";
import Navbar from "./Components/Navbar/navbar";

function App() {
  return (
    <>
      <Navbar />
      <FormBuilderCardDisplay />
    </>
  );
}

export default App;
