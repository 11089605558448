import { TableBody, TableCell, TablePagination, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EDIT_EXCEL_FILE_DATA, GET_EXCEL_FILE_DATA } from "../../apiConst";
import { TableComponent } from "../../Common/Table";
import { postRequest, putRequest } from "../interceptor/interceptor";
import { getComparator, stableSort } from "../../Common/Table";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useNavigate } from "react-router";
import { getSentenceFromCamelCase } from "../../utils";
import { useParams } from "react-router";
import { useLocation } from "react-router";
import SelectDropdown from "../../Common/SelectDropdown";
import { useForm } from "react-hook-form";

function FileDataDisplay(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [headCells, setHeadCells] = useState([]);

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [searchVal, setSearchVal] = useState("");
  const [totalCount, setTotalCount] = useState(rowsPerPage);

  const {
    register,
    getValues,
    formState: { errors },
  } = useForm({});

  const settingColumnsData = (data) => {
    let columnsObj = [];
    let columnData = [];
    if (location?.state?.column) {
      columnData = { ...location.state.column };
    } else {
      const firstRowData = data[0] || {};

      const {
        _id,
        userId,
        createdAt,
        companyId,
        metaDataId,
        name,
        ...remainingData
      } = firstRowData;
      columnData = { ...remainingData };
    }

    Object.keys(columnData).forEach((col) => {
      columnsObj = [
        ...columnsObj,
        {
          id: col,
          label: getSentenceFromCamelCase(col),
        },
      ];
    });
    setHeadCells(columnsObj);
  };

  const getAllData = (pg = 1, size = 100, search = "", searchKey = null) => {
    setIsLoading(true);
    const { searchField } = getValues();
    let payload = {
      metaDataId: params.fileId || "",
    };
    const key = searchKey ? searchKey : searchField;
    if (key && search) {
      payload = {
        ...payload,
        [key]: search,
      };
    }
    setExcelData([]);
    postRequest(
      `${GET_EXCEL_FILE_DATA}?page=${pg}&size=${size}
          `,
      payload
    )
      .then((res) => {
        if (res && res.data.data.length > 0) {
          const { data, total } = res.data;
          setExcelData([...data]);
          settingColumnsData([...data]);

          setTotalCount(total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setExcelData([]);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (location?.state) {
      setIsView(location.state?.isView || false);
    }
    getAllData();
  }, [location.state]);

  const goBack = () => {
    navigate(-1);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await getAllData(Number(newPage + 1), rowsPerPage, "");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    getAllData(1, parseInt(event.target.value), "");
  };

  const updateField = (id, payload) => {
    putRequest(`${EDIT_EXCEL_FILE_DATA}?id=${id}`, payload)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const onFieldBlur = (row, id, e) => {
    const { value } = e.target;
    const payload = {
      [id]: value,
    };

    updateField(row._id, payload);
  };

  const handleDropdown = (e) => {
    if (searchVal) {
      getAllData(1, rowsPerPage, searchVal, e.target.value);
    }
  };

  const componentDisplay = () => {
    return (
      <div className="mr-3 md:w-52 mb-[-15px] xs:w-96">
        {headCells.length > 0 && (
          <SelectDropdown
            fieldName="searchField"
            options={headCells}
            optionsLabelKey="label"
            optionsValueKey="id"
            register={register}
            onChange={handleDropdown}
            errors={errors}
            fieldType="select"
            className="min-h-[auto] w-full  border-1 bg-light py-2 text-sm text-gray-900 bg-white-900 rounded-md pl-2 focus:outline-none focus:bg-white focus:text-gray-900"
            emptyOption={false}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      {isLoading && <FullScreenLoader />}
      <button
        type="button"
        className="m-3 inline-block rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
        data-te-ripple-init
        data-te-ripple-color="light"
        onClick={goBack}
      >
        Back
      </button>
      <div className="mx-2 shadow-lg" style={{ width: "99%", display: "flex" }}>
        <div style={{ width: "99%" }}>
          <TableComponent
            numSelected={0}
            searchVal={searchVal}
            headingText={"Excel File Data"}
            order={order}
            orderBy={orderBy}
            rowCount={excelData.length || 0}
            tableRowsData={excelData}
            headCells={headCells}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setSearchVal={setSearchVal}
            rowsPerPage={rowsPerPage}
            getAllData={getAllData}
            componentDisplay={componentDisplay}
          >
            <TableBody className="table-body">
              {excelData &&
                excelData.length > 0 &&
                stableSort(excelData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    return (
                      <TableRow
                        hover
                        role="radio"
                        name="selectedRow"
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell></TableCell>
                        {headCells.map((cell) => {
                          return (
                            <TableCell key={cell.id + index}>
                              <input
                                type="text"
                                name={cell.id}
                                defaultValue={row[cell.id]}
                                onBlur={(e) => onFieldBlur(row, cell.id, e)}
                                style={{ border: "none" }}
                                disabled={isView ? true : false}
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </TableComponent>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Entries per page: "}
            labelDisplayedRows={({ from, to, count }) =>
              `Showing ${from}-${to} of ${count} entries`
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FileDataDisplay;
