import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { FORM_BUILDER_H_ADD, GET_IP_ADDRESS, START_API } from "../../apiConst";
import { getRequest, postRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import axios from "axios";
import FormBuilderDataDisplay from ".";
import { toast } from "react-toastify";
import SocialShare from "./SocialShare";

const FormBuilderStartPage = () => {
  const [ipAddress, setIpAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [fields, setFields] = useState([]);
  const [formDetails, setFormDetails] = useState();
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    getFieldsDetails();
    setWebUrl();
    if (location?.state?.isPreview) {
      setIsPreview(true);
    } else {
      getIPAddress();
    }
  }, [location]);

  const setWebUrl = () => {
    let weburl = window.location.href;
    let hostname = window.location.hostname;
    if (hostname === "localhost") {
      weburl = `https://form.itpros.tech${location.pathname}`;
    }
    setUrl(weburl);
  };

  const getFieldsDetails = () => {
    setIsLoading(true);
    getRequest(`${FORM_BUILDER_H_ADD}form_h&id=${params.formUniqueId}`)
      .then((res) => {
        setIsLoading(false);
        if (res && res.data) {
          const { isPublish, fields } = res.data;
          setFields(fields);
          setFormDetails(res.data);
          if (isPublish) {
            setIsPublish(isPublish);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const getIPAddress = () => {
    setIsLoading(true);
    axios
      .get(`${GET_IP_ADDRESS}`)
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          const { ip } = res.data;
          setIpAddress(ip);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const start = () => {
    if (isPublish) {
      const data = {
        ipAddress,
        formUniqueId: params.formUniqueId,
      };
      postRequest(`${START_API}`, data)
        .then((res) => {
          const { insertedId, token, exist, existingId } = res.data.data;
          console.log(insertedId);
          if (exist) {
            navigate(
              `/form-builder-data/${params.formUniqueId}/${existingId}`,
              {
                state: {
                  token: token || null,
                  exist: true,
                },
              }
            );
          } else {
            navigate(
              `/form-builder-data/${params.formUniqueId}/${insertedId}`,
              {
                state: {
                  token: token || null,
                },
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Form is not Published Yet");
    }
  };

  const backToTable = () => {
    navigate("/");
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="mt-5 flex flex-row justify-end">
        <div className="mr-5">
          <SocialShare url={url} />
        </div>

        <div className="">
          <button
            type="button"
            className="inline-block mr-5 cursor-pointer rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            onClick={backToTable}
          >
            Back
          </button>
        </div>
      </div>
      <div className="md:m-10 mt-12 xs:m-2">
        <div className="flex justify-center w-full">
          <div
            className="border-2 d-flex justify-center overflow-hidden overflow-y-scroll"
            style={{ width: "100%", maxHeight: "80vh" }}
          >
            <span className="pointer-events-none">
              {fields && formDetails && (
                <FormBuilderDataDisplay
                  isPreview={true}
                  fields={fields}
                  formDetails={formDetails}
                />
              )}
            </span>
          </div>
        </div>
        {!isPreview && (
          <div className="flex justify-center mt-10">
            <button
              type="button"
              className="inline-block mr-10 absolute  rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              onClick={start}
            >
              Start Filling Form
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FormBuilderStartPage;
