import React from "react";

const FormComponent = ({
  fieldId,
  fieldName,
  fieldInfo,
  fieldText,
  fieldType,
  register,
  validationObj = {},
  onChange,
  className = "shadow appearance-none border-2 border-gray-200 rounded placeholder-gray-500 placeholder-opacity-75  w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500",
  labelClassName = "block text-gray-500 font-bold md:text-left mb-1 md:mb-2 pr-4",
  errors,
  multiple,
  required,
  disabled,
  value,
  onClick,
  onBlur,
}) => {
  return (
    <>
      <div>
        <label className={labelClassName}>{fieldText}</label>
      </div>
      <div>
        {fieldInfo ? (
          <div>{fieldInfo}</div>
        ) : fieldType === "textarea" ? (
          <textarea
            id={fieldId}
            name={fieldId}
            type={fieldType}
            className={className}
            placeholder={fieldText}
            {...register(fieldId, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              validationObj,
            })}
            disabled={disabled}
          />
        ) : (
          <input
            id={fieldId}
            name={fieldId}
            type={fieldType}
            className={className}
            placeholder={fieldText}
            {...register(fieldId, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              validationObj,
            })}
            disabled={disabled}
          />
        )}
      </div>
      <p className="py-2 text-danger">
        {errors[fieldId] && errors[fieldId].message}
      </p>
    </>
  );
};

export default FormComponent;
