import React, { useEffect } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../../css/table.css";
import { get, isEmpty } from "lodash";
import { getComparator, stableSort, TableComponent } from "../../Common/Table";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import { postRequest } from "../interceptor/interceptor";
import {
  GET_COLLECTION_ALIAS_TABLE_DETAILS,
  GET_COMPANY_BY_GEO_LOCATION,
  GET_OJ_ENDPOINT,
} from "../../apiConst";
import { FaRegEye } from "react-icons/fa";
import MapFeature from "./Map";
import Navbar from "../Navbar/navbar";
import SelectDropdown from "../../Common/SelectDropdown";
import { useForm } from "react-hook-form";

export default function CollectionSearch() {
  const [collectionId, setCollectionId] = useState("");
  const [singleCollectionDetail, setSingleCollectionDetail] = useState(null);
  const [order, setOrder] = useState("asc");
  const [tableRowsData, setTableRowsData] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchVal, setSearchVal] = useState("");
  const [totalCount, setTotalCount] = useState(rowsPerPage);

  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();

  let navigate = useNavigate();

  const {
    register,
    getValues,
    formState: { errors },
  } = useForm({});

  const getCollectionDetails = (id) => {
    setIsLoading(true);
    const payload = {
      type: id,
    };
    postRequest(
      `${GET_COLLECTION_ALIAS_TABLE_DETAILS}
      `,
      payload
    )
      .then((res) => {
        setIsLoading(false);
        if (res && res.data.data.length > 0) {
          const { fields } = res.data.data[0];
          const obj = fields; //Getting Details From Params ID
          if (obj) {
            setSingleCollectionDetail(obj);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getAllData = (pg = 1, size = 10, search = "", searchKey = null) => {
    setIsLoading(true);
    const { searchField } = getValues();
    let payload = {};
    const key = searchKey ? searchKey : searchField;
    if (key && search) {
      payload = {
        [key]: search,
      };
    }
    postRequest(
      `${GET_OJ_ENDPOINT}/${singleCollectionDetail.tableName}/search?page=${pg}&size=${size}
      `,
      payload
    )
      .then((res) => {
        if (res && res.data.data.length > 0) {
          setTableRowsData(res.data.data);
          setTotalCount(res.data.total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setPage(0);
          setTotalCount(0);
          setTableRowsData([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (params) {
      const { collectionId } = params;

      setCollectionId(collectionId);
      getCollectionDetails(collectionId);
    }
  }, []);

  useEffect(() => {
    if (singleCollectionDetail) {
      if (collectionId !== "company") {
        getAllData();
      }
    }
  }, [singleCollectionDetail]);

  const handleClick = (event, row) => {
    setSelected(row);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await getAllData(Number(newPage + 1), rowsPerPage, "");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    getAllData(1, parseInt(event.target.value), "");
  };

  const handleView = (row) => {
    navigate(`/e/${collectionId}/${row[singleCollectionDetail.id]}`);
  };

  const getCompanyByLocation = (payload) => {
    setIsLoading(true);
    postRequest(`${GET_COMPANY_BY_GEO_LOCATION}`, payload)
      .then((res) => {
        console.log(res);
        if (res && res.data.data) {
          setTableRowsData(res.data.data);
          setTotalCount(res.data.total);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleSearchFieldDropdown = (e) => {
    if (searchVal) {
      getAllData(1, rowsPerPage, searchVal, e.target.value);
    }
  };

  const componentDisplay = () => {
    return (
      <div className="mr-3 md:w-52 mb-[-15px] xs:w-96">
        {get(singleCollectionDetail, "headCells").length > 0 && (
          <SelectDropdown
            fieldName="searchField"
            options={get(singleCollectionDetail, "headCells")}
            optionsLabelKey="label"
            optionsValueKey="id"
            register={register}
            onChange={handleSearchFieldDropdown}
            errors={errors}
            fieldType="select"
            className="min-h-[auto] w-full  border-1 bg-light py-2 text-sm text-gray-900 bg-white-900 rounded-md pl-2 focus:outline-none focus:bg-white focus:text-gray-900"
            emptyOption={false}
          />
        )}
      </div>
    );
  };

  const cellDataDisplay = (cell, row) => {
    if (get(cell, "isBoolean")) {
      return (
        <TableCell>
          {get(row, cell.accessor) === true
            ? get(cell, "resolveLable")
            : get(cell, "rejectLabel")}
        </TableCell>
      );
    } else if (get(cell, "isButton")) {
      return (
        <TableCell>
          {get(cell, "buttonList").map((btn) => {
            return (
              <button
                className={get(btn, "className")}
                onClick={() => handleView(row)}
              >
                {btn.btnName === "View" ? <FaRegEye /> : btn.btnName}
              </button>
            );
          })}
        </TableCell>
      );
    }
    return <TableCell>{get(row, cell.accessor)}</TableCell>;
  };

  return (
    <React.Fragment>
      {isLoading && <FullScreenLoader />}
      <Navbar />
      {singleCollectionDetail && (
        <>
          {collectionId === "company" ? (
            <>
              <MapFeature
                getCompanyByLocation={getCompanyByLocation}
                tableRowsData={tableRowsData}
                setIsLoading={setIsLoading}
              />
            </>
          ) : (
            <div
              className="mx-2 shadow-lg"
              style={{ width: "99%", display: "flex" }}
            >
              <div style={{ width: "99%" }}>
                <TableComponent
                  numSelected={isEmpty(selected) ? 0 : 1}
                  searchVal={searchVal}
                  headingText={singleCollectionDetail?.title}
                  order={order}
                  orderBy={orderBy}
                  rowCount={tableRowsData.length || 0}
                  tableRowsData={tableRowsData}
                  selected={selected}
                  headCells={singleCollectionDetail.headCells || []}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  setSearchVal={setSearchVal}
                  rowsPerPage={rowsPerPage}
                  getAllData={getAllData}
                  componentDisplay={componentDisplay}
                >
                  <TableBody className="table-body">
                    {tableRowsData &&
                      tableRowsData.length > 0 &&
                      stableSort(
                        tableRowsData,
                        getComparator(order, orderBy)
                      ).map((row, index) => {
                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row)}
                            role="radio"
                            name="selectedRow"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell></TableCell>
                            {singleCollectionDetail.headCells.map((cell) => {
                              return cellDataDisplay(cell, row);
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </TableComponent>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Entries per page: "}
                  labelDisplayedRows={({ from, to, count }) =>
                    `Showing ${from}-${to} of ${count} entries`
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
}
