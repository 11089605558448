export const queTypeList = [
  { label: "Textbox", value: "text" },
  { label: "Info", value: "info" },
  { label: "Text Area", value: "textarea" },
  { label: "Radio", value: "radio" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Number", value: "number" },
  { label: "Date", value: "date" },
  { label: "File Upload", value: "file" },
];

export const visibilityOptionList = [
  { label: "Private", value: "private" },
  { label: "Public", value: "public" },
  { label: "Restricted", value: "restricted" },
  { label: "Shared", value: "shared" },
];

export const typeOptionList = [
  { label: "Application", value: "application" },
  { label: "Exam", value: "exam" },
  { label: "Survey", value: "survey" },
  { label: "Form", value: "form" },
  { label: "PDF Form", value: "pdfForm" },
];

export const layoutOptionList = [
  { label: "Classic", value: "classic" },
  { label: "Card", value: "card" },
];

export const categoryOptionList = [
  { label: "Payments", value: "payments" },
  { label: "Contact", value: "contact" },
];

export const subCategoryOptionList = [
  { label: "Credit Card", value: "creditCard" },
  { label: "Debit Card", value: "debitCard" },
  { label: "Billing", value: "billing" },
];

export const apiTypeOptionList = [
  { label: "GET", value: "GET" },
  { label: "POST", value: "POST" },
];
