import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { GET_API_DETAILS } from "../../apiConst";
import { apiTypeOptionList } from "../FormBuilder/constant";
import { postRequest, putRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";

const ProxyForm = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const locationStateData = location.state;

  useEffect(() => {
    if (locationStateData && locationStateData.isEdit) {
      reset({
        name: locationStateData?.name,
        url: locationStateData?.url,
        method: locationStateData?.method,
        body: locationStateData.body
          ? JSON.stringify(locationStateData.body)
          : "",
        headers: locationStateData.headers
          ? JSON.stringify(locationStateData.headers)
          : "",
      });
    }
  }, []);

  const addDataToServer = (payload) => {
    setIsLoading(true);
    postRequest(`${GET_API_DETAILS}/add`, payload)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        backToTable();
        reset();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const editDataFromServer = (payload) => {
    setIsLoading(true);
    putRequest(`${GET_API_DETAILS}/edit?id=${locationStateData._id}`, payload)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        backToTable();
        reset();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onSubmit = (data) => {
    const payload = {
      ...data,
      body: data.body ? JSON.parse(data.body) : {},
      headers: data.headers ? JSON.parse(data.headers) : {},
    };
    if (locationStateData && locationStateData.isEdit) {
      editDataFromServer(payload);
    } else {
      addDataToServer(payload);
    }
  };

  const backToTable = () => {
    navigate("/proxy");
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="flex justify-center text-2xl text-primary w-full m-3 font-bold">
        <h1>API Details</h1>
      </div>
      <div className="flex justify-end">
        <button
          type="button"
          className="inline-block mr-10 absolute cursor-pointer rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          onClick={backToTable}
        >
          Back
        </button>
      </div>

      <div className="container flex flex-wrap rounded-lg bg-white p-6 m-5 dark:bg-neutral-900">
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full max-w-xl m-auto bg-teal-lighter">
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Name
                </label>
              </div>
              <div className="md:w-2/3">
                <input
                  id={`name`}
                  name="name"
                  {...register(`name`, {
                    required: "Please Enter Name",
                  })}
                  type="text"
                  className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
                  placeholder="Enter Name"
                />
                <div className="text-red-500 text-md m-1 italic">
                  {errors["name"] && errors["name"].message}
                </div>
              </div>
            </div>

            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  URL
                </label>
              </div>
              <div className="md:w-2/3">
                <input
                  id={`url`}
                  name="url"
                  {...register(`url`, {
                    required: "Please Enter URL",
                  })}
                  type="text"
                  className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
                  placeholder="Enter URL"
                />
                <div className="text-red-500 text-md m-1 italic">
                  {errors["url"] && errors["url"].message}
                </div>
              </div>
            </div>

            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Method
                </label>
              </div>
              <div className="md:w-2/3">
                <select
                  id={`method`}
                  name="method"
                  {...register(`method`, {
                    required: "Please Select Method",
                  })}
                  className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
                  placeholder="Please Select"
                >
                  <option value="">Please Select</option>
                  {apiTypeOptionList?.map((item) => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <div className="text-red-500 text-md m-1 italic">
                  {errors["method"] && errors["method"].message}
                </div>
              </div>
            </div>

            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Headers
                </label>
              </div>
              <div className="md:w-2/3">
                <textarea
                  id={`headers`}
                  name="headers"
                  {...register(`headers`, {})}
                  type="text"
                  rows={5}
                  className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
                  placeholder={`Enter Headers Details in JSON format like 
                    {
                    "key": "value"
                    }`}
                />
              </div>
            </div>

            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
                  Body
                </label>
              </div>
              <div className="md:w-2/3">
                <textarea
                  id={`body`}
                  name="body"
                  {...register(`body`, {})}
                  type="text"
                  rows={5}
                  className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
                  placeholder={`Enter Body Details in JSON format like 
                  {
                  "key": "value"
                  }`}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end" style={{ width: "75%" }}>
            <button
              type="button"
              className="shadow bg-success-600 mr-2 hover:bg-success-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </button>

            <button
              type="button"
              className="shadow bg-danger-600 mr-2 hover:bg-danger-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              onClick={backToTable}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProxyForm;
