import React from "react";
import { FaRegFilePdf, FaRegTimesCircle } from "react-icons/fa";

const ImageDisplayField = ({
  question,
  questionIndex,
  section,
  deleteFile,
  sectionIndex,
}) => {
  const filePath = question[`field_file_${section}_${questionIndex}`];
  const fileType = question[`field_fileType_${section}_${questionIndex}`];
  const fileName = question[`field_fileName_${section}_${questionIndex}`];
  return (
    <div className="mt-3 relative">
      {filePath && (
        <>
          {fileType.includes("image") ? (
            <img src={filePath} key={filePath} width="100px" alt="img" />
          ) : fileType.includes("pdf") ? (
            <div onClick={() => window.open(filePath)}>
              <FaRegFilePdf className="text-5xl text-red-600" />
              <div>{fileName}</div>
            </div>
          ) : (
            { fileName }
          )}
          <div
            className="crossIcon absolute top-0 left-24"
            onClick={() =>
              deleteFile(filePath, section, sectionIndex, questionIndex)
            }
          >
            <FaRegTimesCircle className="bg-white" />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageDisplayField;
