import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { FORM_BUILDER_H_ADD } from "../../apiConst";
import FormComponent from "../../Common/FormComponent";
import { getRequest, putRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useForm } from "react-hook-form";
import FormChildrenComponent from "../../Common/FormChildrenComponent";
import { toast } from "react-toastify";
import { FaRegFilePdf } from "react-icons/fa";

const typeList = ["radio", "checkbox"];

const FormBuilderDataDisplay = (props) => {
  const { isPreview } = props;
  const [fields, setFields] = useState([]);
  const [formDetails, setFormDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userToken, setUserToken] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (location.state) {
      const { token } = location.state;
      if (token) {
        setUserToken(token);
      } else {
        setUserToken(localStorage.getItem("userAuthToken"));
      }
    } else {
      setUserToken(localStorage.getItem("userAuthToken"));
    }
  }, [location.state, param.dataInsertedId]);

  useEffect(() => {
    if (userToken || props?.isCard || props?.isPreview) {
      if (!props?.isPreview) getFieldsData();
      else {
        setFields(props.fields);
        setFormDetails(props.formDetails);
      }
    } else {
      if (!location.state && !localStorage.getItem("userAuthToken")) {
        navigate(`/form-builder-data/${param.formUniqueId}`);
      }
    }
  }, [
    userToken,
    props?.isCard,
    props?.isPreview,
    props.fields,
    props.formDetails,
    location.state,
    navigate,
    param.formUniqueId,
  ]);

  const saveDataToServer = (obj) => {
    putRequest(
      `${FORM_BUILDER_H_ADD}form_d&id=${param.dataInsertedId}&token=${userToken}`,
      obj
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFieldsExistingData = () => {
    getRequest(
      `${FORM_BUILDER_H_ADD}form_d&id=${param.dataInsertedId}&token=${userToken}`
    )
      .then((res) => {
        if (res && res.data) {
          reset(res.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getFieldsData = () => {
    setIsLoading(true);
    getRequest(
      `${FORM_BUILDER_H_ADD}form_h&id=${param.formUniqueId}&token=${userToken}`
    )
      .then((res) => {
        if (res && res.data) {
          const { fields } = res.data;
          setFields(fields);
          setFormDetails(res.data);
          getFieldsExistingData();
        } else {
          setIsLoading(false);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const onChange = (e) => {
    const { name, value, type } = e.target;
    if (typeList.includes(type)) {
      const obj = {
        [name]: e.target.checked ? value : null,
      };
      saveDataToServer(obj);
    }
  };

  const onBlur = (e) => {
    const { name, value } = e.target;
    const obj = {
      [name]: value,
    };
    saveDataToServer(obj);
  };

  const onSubmit = (data) => {
    console.log("On Submit", data);
    const { createdAt, _id, ...rest } = data;
    saveDataToServer(rest);
    navigate("/");
    toast.success("Data Saved Successfully");
  };

  const backToTable = () => {
    navigate("/");
  };

  const renderFields = () => {
    const cardLayout = formDetails?.layout;
    if (cardLayout === "card") {
      const displayFields = Object.keys(fields).map((section, sectionIndex) => {
        if (fields[section].questions.length > 0) {
          return (
            <>
              <div
                className={`flex w-full flex-wrap justify-between font-bold m-2 ${
                  currentIndex == sectionIndex ? "block" : "hidden"
                }`}
                key={`${section}_${sectionIndex}`}
              >
                <h2 className="w-full block mb-3 text-blue-400">
                  {fields[section]?.section_title}
                </h2>
                {fields[section].questions.map((field, i) => {
                  const {
                    field_id,
                    field_text,
                    field_type,
                    field_info,
                    field_className,
                    options,
                    children,
                    multiple,
                    required,
                    disabled,
                    field_file,
                    field_fileType,
                    field_fileName,
                    validationObj = {},
                  } = field;
                  const fieldTextLength = field_text?.length || 0;
                  return (
                    <>
                      {children ? (
                        <div
                          className="flex w-full flex-wrap"
                          key={`${field_id}_${i}`}
                        >
                          <FormChildrenComponent
                            field_id={field_id}
                            field_text={field_text}
                            register={register}
                            onChange={onChange}
                            field_type={field_type}
                            errors={errors}
                            children={children}
                            validationObj={validationObj}
                          />
                        </div>
                      ) : field_type === "file" ? (
                        <div className="w-full" key={`${field_id}_${i}`}>
                          {field_file && (
                            <>
                              <div>
                                <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4">
                                  {field_text}
                                </label>
                              </div>
                              {field_fileType.includes("image") ? (
                                <img
                                  src={field_file}
                                  width="150px"
                                  className="p-3"
                                  alt="Img"
                                />
                              ) : field_fileType.includes("pdf") ? (
                                <div
                                  className="cursor-pointer mt-2"
                                  onClick={() => window.open(field_file)}
                                >
                                  <FaRegFilePdf className="text-5xl text-red-600" />
                                  <div className="mt-2">{field_fileName}</div>
                                </div>
                              ) : (
                                <div onClick={() => window.open(field_file)}>
                                  {field_fileName}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ) : (
                        <div
                          className={`w-full ${
                            fieldTextLength > 40 ? "" : "md:w-1/2"
                          }  px-2 mb-6 md:mb-0`}
                          key={`${field_id}_${i}`}
                        >
                          <FormComponent
                            fieldId={field_id}
                            fieldText={field_text}
                            fieldType={field_type}
                            fieldInfo={field_info}
                            register={register}
                            onChange={onChange}
                            onBlur={onBlur}
                            className={field_className}
                            multiple={multiple}
                            required={required}
                            disabled={disabled || false}
                            errors={errors}
                            options={options}
                            validationObj={validationObj}
                          />
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </>
          );
        } else {
          return <></>;
        }
      });

      return (
        <>
          {displayFields}
          <div className="flex justify-between w-full mt-5 px-4">
            <div>
              <button
                type="button"
                className={`inline-block ${
                  currentIndex === 0
                    ? "pointer-events-none opacity-75"
                    : "pointer-events-auto opacity-100"
                }  rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10`}
                onClick={() => setCurrentIndex(currentIndex - 1)}
              >
                Prev
              </button>
            </div>
            <div>
              <button
                type="button"
                className={`inline-block ${
                  currentIndex === Number(Object.keys(fields).length) - 1
                    ? "pointer-events-none opacity-75"
                    : "pointer-events-auto opacity-100"
                } rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10`}
                onClick={() => setCurrentIndex(currentIndex + 1)}
              >
                {" "}
                Next{" "}
              </button>
            </div>
          </div>
        </>
      );
    } else {
      const displayFields = Object.keys(fields).map((section, sectionIndex) => {
        if (fields[section].questions.length > 0) {
          return (
            <div
              className="flex w-full flex-wrap justify-between font-bold m-2"
              key={`${section}_${sectionIndex}`}
            >
              <h2 className="w-full block mb-3 text-blue-400">
                {fields[section]?.section_title}
              </h2>
              {fields[section].questions.map((field, i) => {
                const {
                  field_id,
                  field_text,
                  field_type,
                  field_info,
                  field_className,
                  options,
                  children,
                  multiple,
                  required,
                  disabled,
                  field_file,
                  field_fileType,
                  field_fileName,
                  validationObj = {},
                } = field;
                const fieldTextLength = field_text?.length || 0;
                return (
                  <>
                    {children ? (
                      <div
                        className="flex w-full flex-wrap"
                        key={`${field_id}_${i}`}
                      >
                        <FormChildrenComponent
                          field_id={field_id}
                          field_text={field_text}
                          register={register}
                          onChange={onChange}
                          field_type={field_type}
                          errors={errors}
                          children={children}
                          validationObj={validationObj}
                        />
                      </div>
                    ) : field_type === "file" ? (
                      <div className="w-full" key={`${field_id}_${i}`}>
                        {field_file && (
                          <>
                            <div>
                              <label className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4">
                                {field_text}
                              </label>
                            </div>
                            {field_fileType.includes("image") ? (
                              <img
                                src={field_file}
                                width="150px"
                                className="p-3"
                                alt="Img"
                              />
                            ) : field_fileType.includes("pdf") ? (
                              <div
                                className="cursor-pointer mt-2"
                                onClick={() => window.open(field_file)}
                              >
                                <FaRegFilePdf className="text-5xl text-red-600" />
                                <div className="mt-2">{field_fileName}</div>
                              </div>
                            ) : (
                              <div onClick={() => window.open(field_file)}>
                                {field_fileName}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div
                        className={`w-full ${
                          fieldTextLength > 40 ? "" : "md:w-1/2"
                        }  px-2 mb-6 md:mb-0`}
                        key={`${field_id}_${i}`}
                      >
                        <FormComponent
                          fieldId={field_id}
                          fieldText={field_text}
                          fieldType={field_type}
                          fieldInfo={field_info}
                          register={register}
                          onChange={onChange}
                          onBlur={onBlur}
                          className={field_className}
                          multiple={multiple}
                          required={required}
                          disabled={disabled || false}
                          errors={errors}
                          options={options}
                          validationObj={validationObj}
                        />
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          );
        } else {
          return <></>;
        }
      });
      return displayFields;
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {fields && Object.keys(fields).length > 0 && (
        <div className={`${props?.isCard ? "m-0" : "m-10 xs:m-2"}`}>
          {!props?.isCard && (
            <h1 className="text-3xl text-center m-3 text-blue-500">
              {formDetails.title}
            </h1>
          )}

          {!isPreview && (
            <div className="flex justify-end">
              <button
                type="button"
                className="inline-block mr-10 absolute  rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                onClick={backToTable}
              >
                Back
              </button>
            </div>
          )}

          <form
            className={`w-full flex flex-wrap justify-center rounded-lg bg-white ${
              props?.isCard ? "p-1 px-6" : "p-6"
            }  m-15 dark:bg-neutral-900`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              className={` w-full max-w-3xl ${
                props?.isCard ? "p-1" : "p-10 shadow-xl"
              }`}
            >
              <div className="flex flex-wrap -mx-3 mb-6">{renderFields()}</div>
            </div>
            {!isPreview && (
              <div className="flex justify-end mt-5" style={{ width: "75%" }}>
                <button
                  type="submit"
                  className="shadow bg-success-600 mr-2 hover:bg-success-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </button>

                <button
                  type="button"
                  className="shadow bg-danger-600 mr-2 hover:bg-danger-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                  onClick={backToTable}
                >
                  Cancel
                </button>
              </div>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default FormBuilderDataDisplay;
