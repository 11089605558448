import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { queTypeList, typeOptionList, visibilityOptionList } from "./constant";
import Questions from "./Questions";
import {
  DELETE_FILE,
  FORM_BUILDER_H_ADD,
  GET_API_DETAILS,
  GET_CATEGORY,
  GET_LATEST_RECORD,
  UPLOAD_FILE,
} from "../../apiConst";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../interceptor/interceptor";
import { toast } from "react-toastify";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { getThreeDigitNo } from "../../utils";
import moment from "moment";
import { useLocation, useNavigate } from "react-router";
import QuestionHeader from "./QuestionHeader";
import SideBar from "./Sidebar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";

const AddQuestionnaire = () => {
  const [data, setData] = useState({
    section_0: {
      questions: [],
    },
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [categoryOptionList, setCategoryOptionList] = useState([]);
  const [apiDetailsList, setApiDetailsList] = useState([]);
  const [formId, setFormId] = useState("");
  const [formUniqueId, setFormUniqueId] = useState("");
  const [isSave, setIsSave] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const locationStateData = location.state;

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    getCategories();
    getApiDetails();
    if (locationStateData?.isEdit) {
      reset(locationStateData);
      prepareQuestionObj(locationStateData.fields);
      setFormId(locationStateData.formId);
      setFormUniqueId(locationStateData._id);
    } else if (locationStateData?.isUseTemplate) {
      const { _id, formId, title, ...rest } = locationStateData;
      reset(rest);
      prepareQuestionObj(locationStateData.fields);
      setIsSave(true);
      getLatestRecord();
    } else {
      setIsSave(true);
      getLatestRecord();
      handleAddQuestion();
    }
  }, []);

  useEffect(() => {
    console.log("DATA", data);
  }, [data]);

  useEffect(
    (e) => {
      if (locationStateData?.isUseTemplate) {
        onSubmit(getValues(), e, true);
      }
    },
    [formUniqueId]
  );

  const addFormIdToServer = (data) => {
    const obj = {
      formId,
      ...data,
    };
    setIsSave(false);
    postRequest(`${FORM_BUILDER_H_ADD}form_h`, obj).then((res) => {
      const { insertedId } = res.data.data;
      setFormUniqueId(insertedId);
    });
  };

  const getApiDetails = () => {
    setIsLoading(true);
    postRequest(`${GET_API_DETAILS}/search`)
      .then((res) => {
        if (res && res?.data?.data?.length > 0) {
          let options = [];
          res.data.data.map((item, index) => {
            options.push({
              label: item.name,
              value: item._id,
            });
          });
          setIsLoading(false);
          setApiDetailsList(options);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      apiDetailsList.length > 0 &&
      locationStateData?.isEdit &&
      locationStateData?.apiDetail
    ) {
      setValue("apiDetail", locationStateData.apiDetail);
    }
  }, [apiDetailsList]);

  const getCategories = () => {
    getRequest(`${GET_CATEGORY}`)
      .then((res) => {
        if (res && res?.data?.length > 0) {
          let options = [];
          res.data.map((item, index) => {
            const itemName = "section_title";
            if (item[itemName]) {
              options.push({
                label: item[itemName],
                value: `${item[itemName]}-${index}`,
                questions: item.questions,
              });
            }
            return null;
          });
          setCategoryOptionList(options);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLatestRecord = () => {
    getRequest(`${GET_LATEST_RECORD}/form_h`)
      .then(async (res) => {
        if (res && res.data) {
          const latestFormId = res.data[0].formId;
          localStorage.setItem("formNo", latestFormId.substring(3, 6));
          setFormId(generateFormID());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateFormID = () => {
    const formNo = getThreeDigitNo();
    const typeLetter = getValues("type").charAt(0).toUpperCase();
    const date = moment(new Date()).format("YYMMDD");
    const ID = `FRM${formNo}_${typeLetter}${formNo}_${date}`;
    return ID;
  };

  const prepareQuestionObj = (fields) => {
    if (fields) {
      let sections = [];
      Object.keys(fields).map((section) => {
        let sectionObj = [];
        const sectionIndex = section.slice(-1);
        Object.keys(fields[section]).map((sectionKey, sectionKeyIndex) => {
          if (sectionKey.includes("question")) {
            let keyPairs = {};
            sectionObj = fields[section].questions.map((que, i) => {
              let singleObj = {};
              Object.keys(que).map((key) => {
                let childArr = [];
                if (
                  !key.includes("field_text_") &&
                  !key.includes("field_type_")
                ) {
                  const keyName = `${key}_section_${sectionIndex}_${i}`;
                  if (key === "children") {
                    const queFieldType = que["field_type"];
                    que[key].map((child, j) => {
                      let childObj = {};
                      Object.keys(child).map((childKey) => {
                        const childKeyName = `${queFieldType}_text_section_${sectionIndex}_${i}-${j}`;
                        console.log(childKeyName, child[childKey], que[key]);
                        childObj = {
                          ...childObj,
                          [childKeyName]: child[childKey],
                        };
                        setValue(childKeyName, child[childKey]);
                      });
                      childArr = [...childArr, childObj];
                    });
                    singleObj = {
                      ...singleObj,
                      children: childArr,
                    };
                  } else {
                    singleObj = {
                      ...singleObj,
                      [keyName]: que[key],
                    };
                    setValue(keyName, que[key]);
                    keyPairs = {
                      ...keyPairs,
                      [keyName]: que[key],
                    };
                  }
                } else {
                  setValue(key, que[key]);
                  singleObj = {
                    ...singleObj,
                    [key]: que[key],
                  };
                }
              });
              return singleObj;
            });
            console.log("fields[section]", fields[section]);
            setValue(section, {
              ...fields[section],
              questions: {
                ...keyPairs,
              },
            });
          } else {
            const keyName = `${sectionKey}_${sectionIndex}`;
            setValue(keyName, fields[section][sectionKey]);
          }
        });

        sections = {
          ...sections,
          [section]: {
            ...fields[section],
            questions: [...sectionObj],
          },
        };
        console.log("SECTIONS", sections);
      });
      const obj = {
        ...sections,
      };
      console.log(obj);
      setData(obj);
    }
  };

  const handleAddQuestion = (sectionIndex = 0, createSection = false) => {
    autoSaveData();

    let currentData = { ...data };
    let section = "";
    if (createSection) {
      currentData = {
        ...currentData,
        [`section_${sectionIndex}`]: {
          questions: [],
        },
      };
      section = `section_${sectionIndex}`;
    } else {
      section = `section_${sectionIndex}`;
    }

    const i = currentData[section]?.questions?.length;
    currentData[section]?.questions?.push({
      [`field_text_section_${sectionIndex}_${i}`]: "",
      [`field_type_section_${sectionIndex}_${i}`]: "",
    });
    setTimeout(() => {
      setValue(
        `section_${sectionIndex}.questions.field_text_section_${sectionIndex}_${i}`,
        ""
      );
      setValue(
        `section_${sectionIndex}.questions.field_type_section_${sectionIndex}_${i}`,
        ""
      );
    }, 1000);
    setData(currentData);
  };

  const handleAddSection = () => {
    const currentData = { ...data };
    const lastKey =
      Object.keys(currentData)[Object.keys(currentData).length - 1];
    const lastIndex = lastKey && lastKey.split("_");
    const sectionIndex = lastIndex ? Number(lastIndex[1]) + 1 : 0;
    handleAddQuestion(sectionIndex, true);
  };

  const handleAddRadioORCheckbox = (
    index,
    queFieldType,
    section,
    sectionIndex
  ) => {
    const currentData = { ...data };
    const child_index =
      currentData[section].questions[index]["children"].length;
    currentData[section].questions[index]["children"].push({
      [`${queFieldType}_section_${sectionIndex}_${index}-${child_index}`]: "",
      [`${queFieldType}_text_section_${sectionIndex}_${index}-${child_index}`]:
        "",
    });
    setValue(
      `section_${sectionIndex}.questions.${queFieldType}_section_${sectionIndex}_${index}-${child_index}`,
      ""
    );
    setValue(
      `section_${sectionIndex}.questions.${queFieldType}_text_section_${sectionIndex}_${index}-${child_index}`,
      ""
    );
    setData(currentData);
  };

  const handleRemoveRadio = (index, section) => {
    const currentData = { ...data };
    if (currentData[section].questions[index]["children"].length > 1) {
      let lastItem = currentData[section].questions[index]["children"].pop();
      Object.keys(lastItem).map((keyName) => {
        setValue(keyName, "");
      });
    }
    setData(currentData);
  };

  const getFields = (obj, formId) => {
    let sectionArr = [];
    console.log("OBJ-------", obj);
    Object.keys(obj).map((objKey) => {
      if (objKey.includes("section_")) {
        console.log(obj[objKey]);
        const fieldArr = [];
        const sectionIndex = objKey.slice(-1);
        typeof obj[objKey] === "object" && console.log(obj[objKey]);
        typeof obj[objKey] === "object" &&
          !isEmpty(obj[objKey]) &&
          Object.keys(obj[objKey]?.questions).map((keyName, ind) => {
            const fieldId = `${formId}_S${sectionIndex}_Q${Number(
              fieldArr.length + 1
            )}`;
            if (keyName.includes("field_text_")) {
              if (obj[keyName] !== "") {
                const index = keyName.slice(-1);
                const fieldType =
                  obj[`field_type_section_${sectionIndex}_${index}`];
                if (["radio", "checkbox"].includes(fieldType)) {
                  const children = [];
                  Object.keys(obj).map((fieldKey, j) => {
                    if (
                      fieldKey.includes(
                        `${fieldType}_text_section_${sectionIndex}_${index}-`
                      )
                    ) {
                      if (obj[fieldKey]) {
                        children.push({
                          field_id:
                            fieldType === "radio"
                              ? `${fieldId}_Opt1`
                              : `${fieldId}_Opt${children.length + 1}`,
                          field_type: fieldType,
                          field_text: obj[fieldKey],
                        });
                      }
                    }
                  });
                  fieldArr.push({
                    field_id: fieldId,
                    field_text: obj[keyName],
                    field_type: fieldType,
                    children,
                  });
                  console.log(fieldArr);
                } else {
                  let extraObj = {};
                  if (fieldType === "info") {
                    extraObj = {
                      field_info:
                        obj[`field_info_section_${sectionIndex}_${index}`],
                    };
                  }
                  if (fieldType === "file") {
                    extraObj = {
                      field_file:
                        obj[`field_file_section_${sectionIndex}_${index}`],
                      field_fileType:
                        obj[`field_fileType_section_${sectionIndex}_${index}`],
                      field_fileName:
                        obj[`field_fileName_section_${sectionIndex}_${index}`],
                    };
                  }
                  fieldArr.push({
                    field_id: fieldId,
                    field_text: obj[keyName],
                    field_type: fieldType,
                    ...extraObj,
                  });
                }
              }
            }
          });
        if (typeof obj[objKey] === "object" && !isEmpty(obj[objKey])) {
          sectionArr = {
            ...sectionArr,
            [`section_${sectionIndex}`]: {
              [`section_title`]: obj[`section_title_${sectionIndex}`],
              questions: [...fieldArr],
            },
          };
        }
      }
    });
    return sectionArr;
  };

  const addDataToServer = (obj, singleField = false, isUseTemplate = false) => {
    if (formUniqueId && obj?.title !== "") {
      const req = putRequest(
        `${FORM_BUILDER_H_ADD}form_h&id=${formUniqueId}`,
        obj
      );
      req
        .then((res) => {
          console.log(res, singleField);
          setIsLoading(false);
          if (!singleField && !isUseTemplate) {
            toast.success("Form Saved Successfully");
            reset();
            navigate("/form-builder");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          err.data && err.data.message && toast.error(err.data.message);
          console.log(err);
        });
    } else {
      setIsLoading(false);
    }
  };

  const autoSaveData = () => {
    const obj = {
      fields: getFields(getValues(), formId) || {},
    };
    console.log("autoSaveData", obj);
    addDataToServer(obj, true);
  };

  const onCancel = () => {
    navigate("/form-builder");
  };

  const onSubmit = (data, e, isUseTemplate = false) => {
    setIsLoading(true);
    const { title, visibility, status, type, description, layout } = data;
    if (title && visibility && type) {
      let obj = {
        formId,
        companyId: "1234",
        title,
        visibility,
        status,
        type,
        description,
        layout,
        isPublish: false,
        fields: getFields(data, formId) || {},
      };
      console.log("OBJECT", obj);
      addDataToServer(obj, false, isUseTemplate);
    } else {
      setIsLoading(false);
    }
  };

  const onBlur = (e) => {
    const { name, value } = e.target;
    const obj = {
      [name]: value,
    };

    if (!locationStateData?.isEdit && formId && isSave && !formUniqueId) {
      const { title, type } = getValues();
      if (title !== "") {
        addFormIdToServer(obj);
      }
    } else if (formUniqueId) {
      addDataToServer(obj, true);
    }
  };

  const onChange = (e, i, section, sectionIndex) => {
    const { name, value } = e.target;
    if (name.includes("field_type_")) {
      const currentData = { ...data };
      console.log(data);
      currentData[section].questions[i][name] = value;
      if (value === "radio" || value === "checkbox") {
        currentData[section].questions[i]["children"] = [
          {
            [`${value}_section_${sectionIndex}_${i}-0`]: "",
            [`${value}_text_section_${sectionIndex}_${i}-0`]: "",
          },
        ];
      }
      setData(currentData);
    }
  };

  const backToTable = () => {
    navigate(-1);
  };

  const onRemoveField = (e, i, section) => {
    console.log(section);
    const currentData = { ...data };
    const deletedRow = currentData[section].questions.splice(i, 1);
    console.log(deletedRow);
    Object.keys(deletedRow[0]).map((keyName) => {
      setValue(keyName, "");
    });
    setData(currentData);
    autoSaveData();
  };

  const onRemoveSection = (e, section) => {
    const currentData = { ...data };
    currentData[section].questions.map((queObj) => {
      Object.keys(queObj).map((keyName) => {
        setValue(keyName, "");
      });
    });
    Object.keys(currentData[section]).map((keyName) => {
      console.log(keyName);
      if (keyName !== "questions") {
        setValue(keyName, "");
      }
      delete currentData[section][keyName];
    });
    // delete currentData[section];
    setValue(`${section}`, {});
    setData(currentData);
    autoSaveData();
  };

  const addSectionAndQuestion = (questions) => {
    let currentData = { ...data };
    const sectionIndex = Object.keys(currentData).length;
    let sectionObj = {};
    const arr = questions.map((question, index) => {
      let obj = {};
      Object.keys(question).map((queKey) => {
        if (!queKey.includes("field_id")) {
          if (queKey === "children") {
            let childrenArr = [];
            question[queKey].map((child, childIndex) => {
              let childObj = {};
              Object.keys(child).map((childKey, childKeyIndex) => {
                const queFieldType = question["field_type"];
                if (!childKey.includes("field_id")) {
                  const childKeyName = `${queFieldType}_text_section_${sectionIndex}_${index}-${childIndex}`;
                  childObj = {
                    ...childObj,
                    [childKeyName]: child[childKey],
                  };
                  setValue(childKeyName, child[childKey]);
                }
              });
              childrenArr = [...childrenArr, childObj];
            });

            obj = {
              ...obj,
              children: childrenArr,
            };

            sectionObj = {
              ...sectionObj,
              children: childrenArr,
            };
          } else {
            obj = {
              ...obj,
              [`${queKey}_section_${sectionIndex}_${index}`]: question[queKey],
            };
            setValue(
              `${queKey}_section_${sectionIndex}_${index}`,
              question[queKey]
            );
            sectionObj = {
              ...sectionObj,
              [`${queKey}_section_${sectionIndex}_${index}`]: question[queKey],
            };
          }
        }
      });
      return obj;
    });
    setValue(`section_${sectionIndex}`, {
      // ...fields[section],
      questions: { ...sectionObj },
    });
    currentData = {
      ...currentData,
      [`section_${sectionIndex}`]: {
        questions: arr,
      },
    };
    setData(currentData);
    autoSaveData();
  };

  const deleteFile = (url, section, sectionIndex, i) => {
    const currentData = { ...data };
    const obj = {
      bucket: "itPros",
      url,
    };
    console.log(obj, section, sectionIndex, i);
    currentData[section].questions[i][`field_file_${section}_${i}`] = "";
    currentData[section].questions[i][`field_fileType_${section}_${i}`] = "";
    currentData[section].questions[i][`field_fileName_${section}_${i}`] = "";
    setData(currentData);
    setValue(`field_file_${section}_${i}`, "");
    setValue(`field_fileType_${section}_${i}`, "");
    setValue(`field_fileName_${section}_${i}`, "");
    deleteRequest(`${DELETE_FILE}`, obj).then((res) => {
      console.log("Image Deleted");
    });
  };

  const onDrop = useCallback(
    (acceptedFiles, section, sectionIndex, i) => {
      const currentData = { ...data };
      const formData = new FormData();
      formData.append("file", acceptedFiles[0]);

      postRequest(`${UPLOAD_FILE}`, formData, {
        "Content-Type": "multipart/form-data",
      }).then((res) => {
        if (res?.data?.data) {
          const { imageUrl, type, originalname } = res.data.data;
          currentData[section]["questions"][i][`field_file_${section}_${i}`] =
            imageUrl;
          currentData[section]["questions"][i][
            `field_fileType_${section}_${i}`
          ] = type;
          currentData[section]["questions"][i][
            `field_fileName_${section}_${i}`
          ] = originalname;
          setValue(`field_file_${section}_${i}`, imageUrl);
          setValue(`field_fileType_${section}_${i}`, type);
          setValue(`field_fileName_${section}_${i}`, originalname);
          setData(currentData);
        }
      });
    },
    [data, setValue]
  );

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const movedItem = categoryOptionList[source.index];
      console.log("ON DRAG END----", movedItem);
      if (movedItem.questions) {
        console.log(data);
        addSectionAndQuestion(movedItem.questions);
      }
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    position: "static",

    // change background colour if dragging
    background: isDragging ? "lightblue" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex justify-center text-2xl text-primary w-full m-3 font-bold">
          <h1>Form Builder</h1>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="inline-block mr-10 absolute cursor-pointer rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            onClick={backToTable}
          >
            Back
          </button>
        </div>

        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <SideBar
              provided={provided}
              Draggable={Draggable}
              categoryList={categoryOptionList}
              getItemStyle={getItemStyle}
            />
          )}
        </Droppable>

        <div className="container flex flex-wrap rounded-lg bg-white p-6 m-5 dark:bg-neutral-900">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <QuestionHeader
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              errors={errors}
              register={register}
              onChange={onChange}
              onBlur={onBlur}
              typeOptionList={typeOptionList}
              data={data}
              onRemoveField={onRemoveField}
              handleAddQuestion={handleAddQuestion}
              visibilityOptionList={visibilityOptionList}
              handleAddRadioORCheckbox={handleAddRadioORCheckbox}
              handleRemoveRadio={handleRemoveRadio}
              onCancel={onCancel}
              categoryOptionList={categoryOptionList}
              apiDetailsList={apiDetailsList}
            />
            <Droppable droppableId="droppable2">
              {(provided, snapshot) => (
                <Questions
                  handleSubmit={handleSubmit}
                  provided={provided}
                  snapshot={snapshot}
                  Draggable={Draggable}
                  onSubmit={onSubmit}
                  errors={errors}
                  register={register}
                  onChange={onChange}
                  onBlur={onBlur}
                  data={data}
                  onRemoveField={onRemoveField}
                  handleAddQuestion={handleAddQuestion}
                  handleAddSection={handleAddSection}
                  queTypeList={queTypeList}
                  handleAddRadioORCheckbox={handleAddRadioORCheckbox}
                  handleRemoveRadio={handleRemoveRadio}
                  onCancel={onCancel}
                  onRemoveSection={onRemoveSection}
                  onDrop={onDrop}
                  deleteFile={deleteFile}
                />
              )}
            </Droppable>
          </form>
        </div>
      </DragDropContext>
    </>
  );
};

export default AddQuestionnaire;
