import { InfoWindow, Marker } from "@react-google-maps/api";
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router";

const MapMarker = (props) => {
  const { place, onLoad, position } = props;
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const navigate = useNavigate();

  const goToCompanyPage = () => {
    navigate(`/${place.companyId}`);
  };

  return (
    <Marker
      onClick={goToCompanyPage}
      onLoad={onLoad}
      position={position}
      onMouseOver={() => setInfoWindowOpen(true)}
      icon={{
        url: require("./../../images/amazon.png"),
      }}
    >
      {infoWindowOpen && (
        <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
          <div className="tooltip text-center" onClick={goToCompanyPage}>
            <div className="text-lg">{place.title}</div>
            <div style={{ width: "100px", paddingLeft: "10px" }}>
              <Carousel
                autoPlay={true}
                showThumbs={false}
                showArrows={true}
                showStatus={false}
                showIndicators={false}
                interval={3000}
                infiniteLoop={true}
              >
                {place.img.map((image, i) => {
                  return (
                    <div key={image + i}>
                      <img src={image} height={"auto"} alt={place.title} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MapMarker;
