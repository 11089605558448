import React, { useEffect } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../../css/table.css";
import { isEmpty } from "lodash";
import { getComparator, stableSort, TableComponent } from "../../Common/Table";
import { headCells } from "./headcells";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useNavigate } from "react-router";
import { useState } from "react";
import { postRequest, putRequest } from "../interceptor/interceptor";
import { GET_OJ_ENDPOINT, FORM_BUILDER_H_ADD } from "../../apiConst";
import { toast } from "react-toastify";

export default function FormBuilderTable(props) {
  const [order, setOrder] = useState("asc");
  const [tableRowsData, setTableRowsData] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchVal, setSearchVal] = useState("");
  const [totalCount, setTotalCount] = useState(rowsPerPage);
  const [archiveDataDisplay, setArchiveDataDisplay] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  const getAllData = (pg = 1, size = 10, search = "", archiveFlag = false) => {
    setIsLoading(true);

    postRequest(`${GET_OJ_ENDPOINT}/form_h/search?page=${pg}&size=${size}&isArchived=${archiveFlag}&search=${search}&accessControl=private
      `)
      .then((res) => {
        if (res && res.data.data.length > 0) {
          setTableRowsData(res.data.data);
          // setTotalCount(res.msg.total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setPage(0);
          setTotalCount(0);
          setTableRowsData([]);
        }
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleClick = (event, row) => {
    setSelected(row);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await getAllData(Number(newPage + 1), rowsPerPage, "", archiveDataDisplay);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    getAllData(1, parseInt(event.target.value), "", archiveDataDisplay);
  };

  const handleAdd = (event) => {
    navigate("/add-form");
  };

  const handleEdit = (row) => {
    navigate("/add-form", {
      state: {
        ...row,
        isEdit: true,
      },
    });
  };

  const handlePreview = (row) => {
    navigate(`/form-builder-data/${row._id}`, {
      state: {
        ...row,
        isPreview: true,
      },
    });
  };

  const handlePublish = (row) => {
    setIsLoading(true);
    console.log(row);
    const obj = {
      isPublish: row.isPublish ? !row.isPublish : true,
    };
    putRequest(`${FORM_BUILDER_H_ADD}form_h&id=${row._id}`, obj)
      .then((res) => {
        setIsLoading(false);
        getAllData(page, rowsPerPage, "", archiveDataDisplay);
        toast.success(
          obj.isPublish
            ? "Form Published Successfully"
            : "Form Un Published Successfully"
        );
      })
      .catch((err) => {
        setIsLoading(false);
        err.data && err.data.message && toast.error(err.data.message);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {isLoading && <FullScreenLoader />}
      <button
        type="button"
        className="m-3 inline-block rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
        data-te-ripple-init
        data-te-ripple-color="light"
        onClick={handleAdd}
      >
        Add Form Builder
      </button>
      <button
        type="button"
        className="m-3 ml-5 inline-block rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
        data-te-ripple-init
        data-te-ripple-color="light"
        onClick={() => navigate("/proxy")}
      >
        API Details
      </button>
      {/* <PublishPopup targetId={"PublishPopup"} /> */}
      <div className="mx-2 shadow-lg" style={{ width: "99%", display: "flex" }}>
        <div style={{ width: "99%" }}>
          <TableComponent
            numSelected={isEmpty(selected) ? 0 : 1}
            searchVal={searchVal}
            headingText={"Form Builder Data"}
            order={order}
            orderBy={orderBy}
            rowCount={tableRowsData.length || 0}
            tableRowsData={tableRowsData}
            selected={selected}
            headCells={headCells}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setSearchVal={setSearchVal}
            rowsPerPage={rowsPerPage}
            getAllData={getAllData}
            archiveDataDisplay={archiveDataDisplay}
          >
            <TableBody className="table-body">
              {tableRowsData &&
                tableRowsData.length > 0 &&
                stableSort(tableRowsData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const labelId = `enhanced-table-radio-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="radio"
                        name="selectedRow"
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell></TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell>{row.type}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>
                          <button
                            className="rounded border-2 border-primary text-primary px-4 pt-1 pb-1"
                            onClick={() => handleEdit(row)}
                          >
                            Edit
                          </button>{" "}
                          <button
                            className="rounded border-2 border-blue-600 text-blue-600 px-4 pt-1 pb-1"
                            onClick={() => handlePreview(row)}
                          >
                            Preview
                          </button>{" "}
                          <button
                            className={`rounded border-2 ${
                              row.isPublish
                                ? "border-danger text-danger"
                                : "border-success text-success"
                            }  px-4 pt-1 pb-1`}
                            onClick={() => handlePublish(row)}
                          >
                            {row.isPublish ? "Unpublish" : "Publish"}
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </TableComponent>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Entries per page: "}
            labelDisplayedRows={({ from, to, count }) =>
              `Showing ${from}-${to} of ${count} entries`
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}
