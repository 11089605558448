import { get } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate, useParams } from "react-router";
import { GET_COMPANY_DETAILS } from "../../apiConst";
import { getSentenceFromCamelCase } from "../../utils";
import { postRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import Navbar from "../Navbar/navbar";

const SingleCompanyDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const getCompanyData = () => {
    setIsLoading(true);
    const obj = {
      companyId: params.collectionDetailId,
    };

    postRequest(`${GET_COMPANY_DETAILS}/search`, obj).then((res) => {
      if (res && res.data.data.length > 0) {
        setCompanyData(res.data.data[0]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const backToTable = () => {
    navigate(-1);
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Navbar />
      {companyData && (
        <div className="font-roboto">
          <div className="m-3 flex justify-end">
            <button
              type="button"
              className="inline-block mr-10 absolute cursor-pointer rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              onClick={backToTable}
            >
              Back
            </button>
          </div>
          <div className="p-3 m-3">
            <div className="flex justify-between items-center m-5 md:flex-row xs:flex-col">
              <div className="md:w-6/12 sm:w-full xs:w-full">
                <div className="text-7xl font-bold text-primary-600">
                  {companyData.title}
                </div>
                <div className="m-3 mt-5 text-justify">
                  {companyData.description}
                </div>
              </div>
              <div className="md:w-5/12  h-auto xs:w-full">
                <Carousel
                  autoPlay={true}
                  showThumbs={false}
                  interval={5000}
                  showArrows={true}
                  showStatus={false}
                  infiniteLoop={true}
                >
                  {companyData.img.map((image, i) => {
                    return (
                      <div key={image}>
                        <img src={image} alt="Company Pic" />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>

            <div className="m-3 text-2xl font-bold text-primary-500">
              Company Information:
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full text-left text-sm font-light">
                      <tbody>
                        {Object.keys(get(companyData, "companyDetails")).map(
                          (company, i) => {
                            return (
                              <tr
                                className={`dark:border-neutral-500 dark:bg-neutral-700 border-b ${
                                  i % 2 === 0 ? `bg-neutral-100` : `bg-white`
                                }`}
                                key={company + i}
                              >
                                <td className="whitespace-nowrap px-6 py-2 font-medium  capitalize">
                                  {getSentenceFromCamelCase(company)}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2">
                                  {companyData?.companyDetails[company]}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleCompanyDetails;
