import React from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router";

const CompanyDisplay = ({ tableRowsData, singleView }) => {
  const navigate = useNavigate();
  const goToCompanyPage = (companyId) => {
    navigate(`/${companyId}`);
  };

  return (
    <div
      className={`grid bg-white ${
        singleView ? `md:grid-cols-2 xs:grid-cols-1` : `grid-cols-1`
      } `}
      style={{ width: "100%" }}
    >
      {tableRowsData.map((data, i) => {
        return (
          <div className="p-5" key={data?._id || i}>
            <div
              className="flex flex-col w-full rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700  md:flex-row"
              style={{ backgroundColor: "#f0f4f8" }}
            >
              {
                <div className="flex items-center p-3 md:w-[50%]">
                  <Carousel
                    autoPlay={true}
                    showThumbs={false}
                    showArrows={true}
                    showStatus={false}
                    infiniteLoop={true}
                    interval={5000}
                  >
                    {data.img.map((image, i) => {
                      return (
                        <div
                          className="w-full  rounded-t-lg   md:rounded-none md:rounded-l-lg"
                          key={i}
                        >
                          <img
                            src={image}
                            className="w-full object-contain bg-contain"
                            alt={data.title}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              }

              <div className="flex flex-col justify-start py-6 px-3 md:w-[50%]">
                <div className="flex justify-between">
                  <h5 className="mb-2 text-xl font-medium text-neutral-800 dark:text-neutral-50 ">
                    {data.title}
                    <div style={{ marginTop: "-10px" }}>
                      <span className="text-xs mt-0 text-secondary-500">
                        {data?.companyDetails?.country}
                      </span>
                    </div>
                  </h5>
                </div>
                <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200 descText">
                  {data.description}
                </p>
                <p className="text-xs ">
                  <button
                    className="ml-0.5 inline-block rounded border-2 border-primary px-3 pt-1.5 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                    onClick={() => goToCompanyPage(data.companyId)}
                  >
                    View Details
                  </button>
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CompanyDisplay;
