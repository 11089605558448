import React from "react";

const FormChildrenComponent = (props) => {
  const {
    register,
    field_text,
    field_id,
    onChange,
    errors,
    children,
    labelDisplay = true,
    labelClassName = "block text-gray-500 font-bold md:text-left mb-1 md:mb-2 pr-4 ml-[10px]",
    onBlur,
    validationObj = {},
  } = props;

  return (
    <>
      {labelDisplay && <label className={labelClassName}>{field_text}</label>}
      <div>
        <div style={{ width: "100%" }}>
          <div
            className={`flex flex-wrap ${
              children.length > 3 ? "flex-col" : "flex-row"
            } `}
          >
            {children.map((fd) => {
              const {
                field_id,
                field_text,
                field_type,
                field_className,
                required,
                labelDisplay = true,
                disabled,
                placeHolder = "",
                fieldLabelClassName = "block text-gray-600 text-sm font-bold ml-3 leading-6 ",
              } = fd;

              return (
                <>
                  {field_type === "date" ? (
                    <></>
                  ) : (
                    // <FormDateInput
                    //   fieldId={fd.field_id}
                    //   fieldLabel={fd.field_label}
                    //   fieldType={fd.field_type}
                    //   control={control}
                    //   Controller={Controller}
                    //   disabled={fd.disabled}
                    // />
                    <>
                      <div className="pl-3 flex items-center">
                        <input
                          className={field_className}
                          id={field_id}
                          name={field_id}
                          type={field_type}
                          required={required}
                          disabled={disabled || false}
                          autoComplete="off"
                          value={field_text ? field_text : field_id}
                          placeholder={placeHolder}
                          {...register(field_id, {
                            onChange: (e) => onChange && onChange(e),
                            onBlur: (e) => onBlur && onBlur(e),
                            validationObj,
                          })}
                        ></input>
                        {labelDisplay && (
                          <div className="pr-0 pl-0">
                            <label
                              className={fieldLabelClassName}
                              htmlFor={field_id}
                            >
                              {labelDisplay ? field_text : ""}
                            </label>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div>
        <p className="py-2 text-danger">
          {errors[field_id] && errors[field_id].message}
        </p>
      </div>
    </>
  );
};

export default FormChildrenComponent;
