export const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "fileName",
    numeric: false,
    disablePadding: false,
    label: "File Name",
  },
  {
    id: "totalCount",
    numeric: false,
    disablePadding: false,
    label: "Total Records",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];
