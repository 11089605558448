import React from "react";
import { layoutOptionList } from "./constant";

const QuestionHeader = (props) => {
  const {
    errors,
    register,
    onChange,
    onBlur,
    typeOptionList,
    visibilityOptionList,
    apiDetailsList,
  } = props;

  return (
    <div className="w-full max-w-xl m-auto bg-teal-lighter">
      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Title
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            id={`title`}
            name="title"
            {...register(`title`, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              required: "Please Enter Title",
            })}
            type="text"
            className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
            placeholder="Enter Title"
          />
          <div className="text-red-500 text-md m-1 italic">
            {errors["title"] && errors["title"].message}
          </div>
        </div>
      </div>

      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Type
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            id={`type`}
            name="type"
            {...register(`type`, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              required: "Please Select Type",
            })}
            className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
            placeholder="Please Select"
          >
            <option value="">Please Select</option>
            {typeOptionList?.map((item) => (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div className="text-red-500 text-md m-1 italic">
            {errors["type"] && errors["type"].message}
          </div>
        </div>
      </div>

      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Visiblity
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            id={`visibility`}
            name="visibility"
            {...register(`visibility`, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              required: "Please Select visibility",
            })}
            className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
            placeholder="Please Select"
          >
            <option value="">Please Select</option>
            {visibilityOptionList?.map((item) => (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div className="text-red-500 text-md m-1 italic">
            {errors["visibility"] && errors["visibility"].message}
          </div>
        </div>
      </div>

      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Layout
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            id={`layout`}
            name="layout"
            {...register(`layout`, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              required: "Please Select layout",
            })}
            className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
            placeholder="Please Select"
          >
            <option value="">Please Select</option>
            {layoutOptionList?.map((item) => (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div className="text-red-500 text-md m-1 italic">
            {errors["layout"] && errors["layout"].message}
          </div>
        </div>
      </div>

      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Active
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            type="radio"
            id="status"
            name="status"
            value="yes"
            className="w-4 h-4 border-gray-300 ml-3 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
            {...register(`status`, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              required: "Please Select Active Status",
            })}
          />{" "}
          <label className="m-2 text-md  text-gray-900 dark:text-gray-300">
            Yes
          </label>
          <input
            type="radio"
            id="status"
            name="status"
            {...register(`status`, {
              required: "Please Select Active Status",
            })}
            className="w-4 h-4 border-gray-300 ml-3 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
            value="no"
          />{" "}
          <label className="m-2 text-md  text-gray-900 dark:text-gray-300">
            No
          </label>
          <div className="text-red-500 text-md m-1 italic">
            {errors["status"] && errors["status"].message}
          </div>
        </div>
      </div>

      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Api Integration
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            id={`apiDetail`}
            name="apiDetail"
            {...register(`apiDetail`, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
            })}
            className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
            placeholder="Please Select"
          >
            <option value="">Please Select</option>
            {apiDetailsList?.map((item) => (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div className="text-red-500 text-md m-1 italic">
            {errors["apiDetail"] && errors["apiDetail"].message}
          </div>
        </div>
      </div>

      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Description
          </label>
        </div>
        <div className="md:w-2/3">
          <textarea
            id={`description`}
            name="description"
            {...register(`description`, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
            })}
            type="text"
            className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
            placeholder="Enter Description"
            onChange={(e) => onChange(e)}
          />
        </div>
      </div>

      {/* <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Category
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            id={`category`}
            name="category"
            {...register(`category`)}
            className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
            placeholder="Please Select"
            onChange={(e) => onChange(e)}
          >
            <option value="">Please Select</option>
            {categoryOptionList?.map((item) => (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div> */}

      {/* <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Sub Category
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            id={`subCategory`}
            name="subCategory"
            {...register(`subCategory`)}
            className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-500"
            placeholder="Please Select"
            onChange={(e) => onChange(e)}
          >
            <option value="">Please Select</option>
            {subCategoryOptionList?.map((item) => (
              <option key={item.label} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div> */}
    </div>
  );
};

export default QuestionHeader;
