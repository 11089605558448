import React, { useEffect } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../../css/table.css";
import { isEmpty } from "lodash";
import { getComparator, stableSort, TableComponent } from "../../Common/Table";
import { headCells } from "./headcells";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useNavigate } from "react-router";
import { useState } from "react";
import {
  getRequest,
  postRequest,
  putRequest,
} from "../interceptor/interceptor";
import {
  EDIT_EXCEL_META_DATA,
  EXCEL_DOWNLOAD,
  GET_UPLOADED_FILE_DATA,
} from "../../apiConst";
import UploadExcelFile from "./uploadExcelFile";
import { FaDownload, FaRegEdit, FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import SelectDropdown from "../../Common/SelectDropdown";
import { useForm } from "react-hook-form";

export default function ExcelFileUpload(props) {
  const [order, setOrder] = useState("asc");
  const [tableRowsData, setTableRowsData] = useState([]);
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchVal, setSearchVal] = useState("");
  const [totalCount, setTotalCount] = useState(rowsPerPage);

  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  const {
    register,
    getValues,
    formState: { errors },
  } = useForm({});

  const getAllData = (pg = 1, size = 10, search = "", searchKey = null) => {
    setIsLoading(true);
    const { searchField } = getValues();
    let payload = {
      isActive: true,
    };
    const key = searchKey ? searchKey : searchField;
    if (key && search) {
      payload = {
        ...payload,
        [key]: search,
      };
    }
    postRequest(
      `${GET_UPLOADED_FILE_DATA}?page=${pg}&size=${size}
      `,
      payload
    )
      .then((res) => {
        if (res && res.data.data.length > 0) {
          setTableRowsData(res.data.data);
          setTotalCount(res.data.total);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setPage(0);
          setTotalCount(0);
          setTableRowsData([]);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleClick = (event, row) => {
    setSelected(row);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    await getAllData(Number(newPage + 1), rowsPerPage, "");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    getAllData(1, parseInt(event.target.value), "");
  };

  const handleDisplay = (row, isView) => {
    navigate(`/data/${row._id}`, {
      state: {
        ...row,
        isView: isView,
      },
    });
  };

  const handleExport = (row) => {
    setIsLoading(true);
    getRequest(
      `${EXCEL_DOWNLOAD}?id=${row._id}`,
      {},
      { responseType: "arraybuffer" }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          })
        );
        if (url) {
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${row.fileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleDelete = (row) => {
    setIsLoading(true);
    putRequest(`${EDIT_EXCEL_META_DATA}?id=${row._id}`, {
      isActive: false,
    })
      .then((res) => {
        toast.success("File Removed Successfully");
        getAllData(1, rowsPerPage, "");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearchFieldDropdown = (e) => {
    if (searchVal) {
      getAllData(1, rowsPerPage, searchVal, e.target.value);
    }
  };

  const componentDisplay = () => {
    const cells = headCells?.filter((cell) => cell.id !== "action");
    return (
      <div className="mr-3 md:w-52 mb-[-15px] xs:w-96">
        {headCells.length > 0 && (
          <SelectDropdown
            fieldName="searchField"
            options={cells}
            optionsLabelKey="label"
            optionsValueKey="id"
            register={register}
            onChange={handleSearchFieldDropdown}
            errors={errors}
            fieldType="select"
            className="min-h-[auto] w-full  border-1 bg-light py-2 text-sm text-gray-900 bg-white-900 rounded-md pl-2 focus:outline-none focus:bg-white focus:text-gray-900"
            emptyOption={false}
          />
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      {isLoading && <FullScreenLoader />}
      <div className="mt-5">
        <UploadExcelFile getAllData={getAllData} setIsLoading={setIsLoading} />
      </div>
      <div className="mx-2 shadow-lg" style={{ width: "99%", display: "flex" }}>
        <div style={{ width: "99%" }}>
          <TableComponent
            numSelected={isEmpty(selected) ? 0 : 1}
            searchVal={searchVal}
            headingText={"Uploaded File Details"}
            order={order}
            orderBy={orderBy}
            rowCount={tableRowsData.length || 0}
            tableRowsData={tableRowsData}
            selected={selected}
            headCells={headCells}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            setSearchVal={setSearchVal}
            rowsPerPage={rowsPerPage}
            getAllData={getAllData}
            componentDisplay={componentDisplay}
          >
            <TableBody className="table-body">
              {tableRowsData &&
                tableRowsData.length > 0 &&
                stableSort(tableRowsData, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const labelId = `enhanced-table-radio-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="radio"
                        name="selectedRow"
                        tabIndex={-1}
                        key={index}
                      >
                        <TableCell></TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>{row.fileName}</TableCell>
                        <TableCell>{row.totalCount}</TableCell>
                        <TableCell>
                          <button
                            className="rounded border-2 border-blue-600 text-blue-600 px-2 pt-1 pb-1"
                            onClick={() => handleDisplay(row, true)}
                            title="VIEW"
                          >
                            <FaRegEye />
                          </button>
                          <button
                            className="rounded border-2 border-warning-600 text-warning-600 px-2 ml-2 pt-1 pb-1"
                            onClick={() => handleDisplay(row, false)}
                            title="EDIT"
                          >
                            <FaRegEdit />
                          </button>
                          <button
                            className="rounded border-2 border-success-600 text-success-600 px-2 ml-2 pt-1 pb-1"
                            onClick={() => handleExport(row)}
                            title="EXPORT"
                          >
                            <FaDownload />
                          </button>
                          <button
                            className="rounded border-2 border-red-600 text-red-600 px-2 ml-2 pt-1 pb-1"
                            onClick={() => handleDelete(row)}
                            title="DELETE"
                          >
                            <FaRegTrashAlt />
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </TableComponent>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={"Entries per page: "}
            labelDisplayedRows={({ from, to, count }) =>
              `Showing ${from}-${to} of ${count} entries`
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
}
