export const replaceSpaceWithDash = (str) => {
  return str.trim().replace(/\s+/g, "-");
};

export function randomNum(minVal = 101, maxVal = 999) {
  var randomVal = minVal + Math.random() * (maxVal - minVal);
  return Math.round(randomVal);
}

export const getThreeDigitNo = (name = "formNo") => {
  let threeDigitNo = localStorage.getItem(name) || "000";
  threeDigitNo = parseInt(threeDigitNo, 10) + 1;
  return ("00" + threeDigitNo).slice(-3);
};

export const getSentenceFromCamelCase = (msg) => {
  let patt1 = /[A-Za-z_]/g;
  let msgArr = msg.match(patt1);
  msgArr[0] =
    typeof msgArr[0] === "string" ? msgArr[0].toUpperCase() : msgArr[0];
  let colName = "";
  for (let i = 0; i < msgArr.length; i++) {
    colName +=
      msgArr[i] === "_"
        ? " "
        : // : msgArr[i] === msgArr[i].toUpperCase()
          // ? " " + msgArr[i]
          msgArr[i];
  }
  return colName;
};
