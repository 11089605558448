import React from "react";
import { FaElementor, FaPlus } from "react-icons/fa";

const SideBar = (props) => {
  const { categoryList, provided, Draggable, getItemStyle } = props;

  return (
    <div className="dark:bg-zinc-800 h-0">
      <nav
        id="full-screen"
        className="sticky top-0 left-0 z-[1035] -translate-x-full overflow-visible bg-white shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] data-[te-sidenav-hidden='false']:translate-x-0 dark:bg-zinc-800"
        data-te-sidenav-init
        data-te-sidenav-content="#content"
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        <div>
          <ul
            className="relative m-0 list-none px-[0.2rem]"
            data-te-sidenav-menu-ref
          >
            <li className="relative">
              <div
                className="group flex h-12 cursor-pointer items-center truncate rounded-[5px] py-4 px-6 text-[0.875rem] text-gray-700 outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                data-te-sidenav-link-ref
              >
                <span className="mr-4 [&>div]:h-3.5 [&>div]:w-3.5 [&>div]:fill-gray-700 [&>div]:transition [&>div]:duration-300 [&>div]:ease-linear group-hover:[&>div]:fill-blue-600 group-focus:[&>div]:fill-blue-600 group-active:[&>div]:fill-blue-600 group-[te-sidenav-state-active]:[&>div]:fill-blue-600 motion-reduce:[&>div]:transition-none dark:[&>div]:fill-gray-300 dark:group-hover:[&>div]:fill-gray-300 ">
                  <div>
                    <FaElementor />
                  </div>
                </span>
                <span>Form Elements</span>
                <span
                  className="absolute right-0 ml-auto mr-[0.8rem] transition-transform duration-300 motion-reduce:transition-none [&>svg]:h-3 [&>svg]:w-3 [&>svg]:fill-gray-600 group-hover:[&>svg]:fill-blue-600 group-focus:[&>svg]:fill-blue-600 group-active:[&>svg]:fill-blue-600 group-[te-sidenav-state-active]:[&>svg]:fill-blue-600 dark:[&>svg]:fill-gray-300"
                  data-te-sidenav-rotate-icon-ref
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    {/* <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
                    <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                </span>
              </div>

              <ul
                className="show !visible relative m-0 hidden list-none p-0 data-[te-collapse-show]:block"
                data-te-sidenav-collapse-ref
              >
                {categoryList?.map((category, index) => {
                  return (
                    <Draggable
                      key={`${category.value}_${index}`}
                      draggableId={`${category.value}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <li
                          key={`${category.value}-${index}`}
                          className="relative"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            className="flex h-6 cursor-pointer items-center truncate rounded-[5px] py-4 pl-[3.4rem] pr-6 text-[0.78rem] text-gray-700 outline-none transition duration-300 ease-linear hover:bg-blue-400/10 hover:text-blue-600 hover:outline-none focus:bg-blue-400/10 focus:text-blue-600 focus:outline-none active:bg-blue-400/10 active:text-blue-600 active:outline-none data-[te-sidenav-state-active]:text-blue-600 data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none dark:text-gray-300 dark:hover:bg-white/10 dark:focus:bg-white/10 dark:active:bg-white/10"
                            key={index}
                            data-te-sidenav-link-ref
                          >
                            {category.label}
                          </div>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <div className="fixed">
        <div className="fixed text-left">
          <button
            id="toggler"
            className=" ml-0 fixed rounded bg-blue-600 px-4 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg"
            data-te-sidenav-toggle-ref
            data-te-target="#full-screen"
            data-te-ripple-init
          >
            <span className="flex justify-between [svg]:h-5 [svg]:w-5 [svg]:text-white">
              <div className="leading-4">
                Add Form <br /> Element
              </div>
              <div className="mt-2.5 ml-2.5">
                <FaPlus />
              </div>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SideBar);
