import React from "react";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { useForm } from "react-hook-form";
import { FaFileExcel, FaSave } from "react-icons/fa";
import { ProgressBar } from "react-loader-spinner";
import { toast } from "react-toastify";
import { UPLOAD_EXCEL_FILE } from "../../apiConst";
import InputField from "../../Common/InputField";
import { postRequest } from "../interceptor/interceptor";

function UploadExcelFile({ getAllData, setIsLoading }) {
  const [files, setFiles] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    const { name } = data;
    if (!name) {
      toast.error("Please Enter Name");
      return;
    }
    if (files && files.length > 0) {
      if (files[0].type.includes("image") || files[0].type.includes("pdf")) {
        toast.error("Please Select Excel or CSV or Text File Only");
      } else {
        setIsUploading(true);
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("name", name);
        console.log(formData);
        postRequest(`${UPLOAD_EXCEL_FILE}`, formData, {
          "Content-Type": "multipart/form-data",
        }).then((res) => {
          setIsUploading(false);
          toast.success("File Uploaded Successfully");
          reset();
          setFiles("");
          getAllData();
        });
      }
    } else {
      toast.error("Please Select File");
    }
  };

  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="md:flex md:items-center mb-3">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Name
          </label>
        </div>
        <div className="md:w-1/3">
          <div className="relative">
            <InputField
              fieldName="name"
              validationObj={{ required: "Please Enter Name" }}
              register={register}
              errors={errors}
              fieldType="text"
              className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
              placeholder="Enter Name"
            />
          </div>
        </div>
      </div>
      <div className="md:flex md:items-center mb-3">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4">
            Upload File
          </label>
        </div>
        <div className="md:w-1/3">
          <div className="border border-dashed p-3 border-gray-500 relative">
            <Dropzone onDrop={(files) => onDrop(files)} multiple={false}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps({})}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
          <div>
            {files && (
              <div className="mt-2 font-bold flex items-center">
                <FaFileExcel className="text-green-700 text-3xl mr-2" />{" "}
                <span>{files[0].name}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mb-0">
        {isUploading && (
          <ProgressBar
            height="80"
            width="90"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#1363aa"
            barColor="#008d4b"
          />
        )}
      </div>
      <div className="flex justify-center border-neutral-100 py-1 px-4 dark:border-neutral-600 dark:text-neutral-50">
        <button
          type="button"
          className="flex shadow bg-primary-600 mr-2 hover:bg-primary-700 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
          onClick={handleSubmit(onSubmit)}
        >
          Submit{" "}
          <span className="ml-2 mt-[3px] text-lg">
            {" "}
            <FaSave />{" "}
          </span>
        </button>
      </div>
    </form>
  );
}

export default UploadExcelFile;
