import React, { useEffect } from "react";
import { useState } from "react";
import { FaPlus, FaRegEye, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router";
import { GET_OJ_ENDPOINT } from "../../apiConst";
import FormBuilderDataDisplay from "../FormBuilderDataDisplay";
import { postRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import PreviewForm from "./PreviewForm";

const FormBuilderCardDisplay = () => {
  const [formHeaderData, setFormHeaderData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [formDetails, setFormDetails] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  let navigate = useNavigate();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    setIsLoading(true);
    const obj = {
      visibility: "public",
    };
    postRequest(`${GET_OJ_ENDPOINT}/form_h/search`, obj).then((res) => {
      if (res && res.data.data.length > 0) {
        setFormHeaderData(res.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setFormHeaderData([]);
      }
    });
  };

  const handlePreview = (row) => {
    setFormDetails(row);
  };

  const handleAdd = () => {
    navigate("/add-form");
  };

  const handleUseTemplate = (row) => {
    navigate("/add-form", {
      state: {
        ...row,
        isUseTemplate: true,
      },
    });
  };

  const searchData = () => {
    if (searchVal) {
      navigate(`/search?val=${searchVal}`);
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="flex justify-between">
        <button
          type="button"
          className="m-3 mb-0 inline-block rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          data-te-ripple-init
          data-te-ripple-color="light"
          onClick={handleAdd}
        >
          <span className="flex">
            <span className="mr-2 mt-[0.10rem]">
              <FaPlus />
            </span>{" "}
            Add Form Builder
          </span>
        </button>
        <div>
          <input
            type="text"
            name="search"
            placeholder="Search company..."
            value={searchVal}
            className=" h-10 w-30 rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
            onChange={(e) => setSearchVal(e.target.value)}
          />
          <button
            className="m-3 mb-0 inline-block rounded border-2 border-primary px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            onClick={searchData}
          >
            <span className="flex">
              Search
              <span className="ml-2 mt-1">
                <FaSearch />
              </span>
            </span>
          </button>
        </div>
      </div>
      <div className="p-5 grid md:grid-cols-4 gap-1 sm:grid-cols-1 xs:grid-cols-1">
        {formHeaderData?.map((form) => {
          return (
            <div
              className="rounded-xl bg-white shadow-xl dark:bg-neutral-700 m-3"
              key={form?._id}
            >
              <div className="py-4">
                <div className="flex justify-between px-6 font-bold text-xl border-b-2 border-neutral-100 py-3 dark:border-neutral-600 dark:text-neutral-50">
                  <div>{form.title}</div>
                  <div>
                    <span className="inline-block bg-success-700 rounded-full px-3 py-1 text-xs font-semibold text-white mr-2 mb-2">
                      {form?.type?.toUpperCase()}
                    </span>
                  </div>
                </div>
                <div className="formCard scale-90 opacity-90 hover:opacity-100 hover:scale-100 duration-500 relative">
                  <div className="relative h-[275px] pointer-events-none scale-100 overflow-hidden w-full skew-y-3 transform-none">
                    <FormBuilderDataDisplay
                      isCard={true}
                      isPreview={true}
                      fields={form.fields || []}
                      formDetails={form || {}}
                    />
                  </div>
                </div>
                <div className="flex pt-1 border-t-2 border-neutral-100 px-2 dark:border-neutral-600 dark:text-neutral-50">
                  <button
                    className="w-96 text-sm  flex items-center justify-center rounded border-2 border-primary-600 text-primary-600 hover:bg-primary-600 hover:text-white px-3 pt-1 pb-1 m-3 xs:text-xs"
                    onClick={() => handlePreview(form)}
                    data-te-toggle="modal"
                    data-te-target={`#previewForm`}
                  >
                    <FaRegEye /> <span className="ml-2">Preview</span>
                  </button>
                  <button
                    className="w-96 text-sm rounded border-2 border-success-600 text-success-600 hover:bg-success-600 hover:text-white px-3 pt-1 pb-1 m-3 xs:text-xs"
                    onClick={() => handleUseTemplate(form)}
                  >
                    Use Template
                  </button>
                </div>
              </div>
              <PreviewForm
                targetId={"previewForm"}
                formDetails={formDetails}
                handleUseTemplate={handleUseTemplate}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FormBuilderCardDisplay;
