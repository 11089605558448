import React from "react";
import { FaRegCopy } from "react-icons/fa";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";

const SocialShare = ({ url }) => {
  const size = 32;

  const copyLink = () => {
    navigator.clipboard.writeText(url).then(
      function () {
        toast.success("Link Copied Successfully");
      },
      function () {
        console.log("Copy error");
      }
    );
  };

  return (
    <div className="flex flex-wrap">
      <div className="mr-2" title="Whatsapp">
        <WhatsappShareButton url={url} title={"Form!"}>
          <WhatsappIcon size={size} round />
        </WhatsappShareButton>
      </div>
      <div className="mr-2" title="Email">
        <EmailShareButton url={url} body="" subject={"Form Link"}>
          <EmailIcon size={size} round />
        </EmailShareButton>
      </div>
      <div className="mr-2" title="Facebook">
        <FacebookShareButton url={url} quote={"Form"} hashtag="#form">
          <FacebookIcon size={size} round />
        </FacebookShareButton>
      </div>
      <div className="mr-2" title="Twitter">
        <TwitterShareButton url={url} quote={"Form!"} hashtag="#form">
          <TwitterIcon size={size} round />
        </TwitterShareButton>
      </div>
      <div
        className="mr-2 cursor-pointer pt-1"
        title="Copy to Clipboard"
        onClick={copyLink}
      >
        <FaRegCopy size={"26"} />
      </div>
    </div>
  );
};

export default SocialShare;
