import { capitalize } from "lodash";
import React from "react";

const SelectDropdown = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldType,
  register,
  validationObj,
  placeholder,
  onChange,
  className,
  errors,
  children,
  multiple,
  required,
  disabled,
  value,
  onClick,
  options,
  optionsLabelKey = "label",
  optionsValueKey = "value",
  emptyOption = true,
}) => {
  return (
    <>
      <select
        key={fieldName}
        id={fieldId}
        name={fieldName}
        {...register(fieldName, {
          onChange: (e) => onChange && onChange(e),
          validationObj,
        })}
        type={fieldType}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
      >
        {emptyOption && (
          <option value="">{placeholder ? placeholder : "Select"}</option>
        )}
        {options && Array.isArray(options)
          ? options.map((option, index) => (
              <React.Fragment>
                {Array.isArray(option) ? (
                  option.map((data, key) => (
                    <option
                      key={data[optionsValueKey] + key}
                      label={data[optionsLabelKey]}
                      value={data[optionsValueKey]}
                    >
                      {data[optionsLabelKey]}
                    </option>
                  ))
                ) : (
                  <option
                    key={option[optionsLabelKey] + index}
                    data={option.data}
                    value={option[optionsValueKey]}
                  >
                    {option[optionsLabelKey]}
                  </option>
                )}
              </React.Fragment>
            ))
          : options
          ? Object.keys(options).map((option, index) => (
              <option key={index} value={option}>
                {capitalize(options[option])}
              </option>
            ))
          : null}
      </select>
      <p className="py-2 text-danger">
        {errors[fieldName] && errors[fieldName].message}
      </p>
    </>
  );
};

export default SelectDropdown;
